import {
  faChurch,
  faClock,
  faMapMarkerAlt,
  faRedo,
  faRss,
  faUser,
  faUsers,
  faBook,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Collapse,
  Descriptions,
  Popover,
  Space,
  Tabs,
  Typography,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';

import { EventConflict, DetailedEvent, EventType } from '../models/calendar';
import AuthService from '../../services/AuthorizationService';
import { gettextCatalog } from '../../services/I18nService';
import StateServiceFactory from '../../services/StateServiceFactory';
import { DisplayDate } from '../../shared/components/FormattedDate';
import { getCommaFormattedNumber } from '../../shared/utils';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const { TabPane } = Tabs;
const { Title } = Typography;
const { Panel } = Collapse;

interface BookingConflictProps {
  conflicts: EventConflict;
  organization: any;
}

interface ConflictingEvents {
  [key: number]: {
    event: DetailedEvent;
    childEvents: DetailedEvent[];
  };
}
const StyledDescriptions = styled(Descriptions)`
  &&&& {
    .ant-descriptions-header {
      margin-bottom: 0;
    }
    .ant-descriptions-title {
      margin-bottom: 8px;
      /* MS: Added font-smoothing to avoid the title from being blurry. It's against best practice but to fix it we need to look into the Bootstrap css that this code is being loaded within. (story/5079)  */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      a {
        font-size: 14px;
      }
    }
  }
`;

const StyledEventListItem = styled.div`
  display: flex;
  margin-bottom: 10px;

  .date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid ${({ theme }) => theme.colors.lightBlue};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.lightBlue};
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    min-width: 40px;
    min-height: 40px;
    margin-right: 10px;

    .date-box__day {
      color: ${({ theme }) => theme.colors.lightBlue};
      font-size: 100%;
      font-weight: 400;
    }

    .date-box__month {
      color: ${({ theme }) => theme.colors.lightBlue};
      font-size: 55%;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: -4px;
    }
  }
  a.showIcon {
    width: fit-content;

    &:hover {
      &::after {
        font: normal normal normal 12px/1 'Font Awesome 5 Pro';
        content: '\f08e';
        margin-left: 5px;
      }
    }
  }
`;

const isFeed = (event) => event && event.type === EventType.Feed;
const isAbsence = (event) => event && event.type === 'absence';
const isRepeatedEvent = (event) => event && event.rrule;
const isEventFromOtherChurchDesk = (organization) => (event) =>
  event.organizationId !== organization.id;

const parseConflictingEvents = (events: DetailedEvent[]): ConflictingEvents => {
  const sortedEvents: DetailedEvent[] = _.sortBy(events, ['startDate']);

  return _.reduce(
    sortedEvents,
    (accumulator: ConflictingEvents, event: DetailedEvent) => {
      // Single event or Parent event
      if (!event.parentEntity) {
        accumulator[event.id] = {
          event,
          childEvents: [],
        };
      } else {
        // Child events
        if (accumulator[event.parentEntity]) {
          // Do not render child events if the parent event is part of the conflicting events
          accumulator[event.parentEntity].childEvents.push(event);
        } else {
          accumulator[event.parentEntity] = {
            event,
            childEvents: [],
          };
        }
      }
      return accumulator;
    },
    {}
  );
};

const ChildEventsComponent: FunctionComponent<{
  childEvents: DetailedEvent[];
  organization: any;
}> = ({ childEvents, organization }) => (
  <>
    {childEvents.map((childEvent) => (
      <EventComponent
        key={`event-${childEvent.id}`}
        event={childEvent}
        childEvents={[]}
        organization={organization}
      />
    ))}
  </>
);

const EventComponent: FunctionComponent<{
  event: DetailedEvent;
  childEvents: DetailedEvent[];
  organization: any;
}> = ({ event, childEvents, organization }) => {
  const isFromOtherChurchDesk = isEventFromOtherChurchDesk(organization);
  const state = StateServiceFactory();

  const prepTimeMinutes =
    event.preparationStartDate &&
    moment(event.startDate).diff(event.preparationStartDate, 'minutes');
  const cleanupTimeMinutes =
    event.cleanupEndDate &&
    moment(event.cleanupEndDate).diff(event.endDate, 'minutes');
  const getCleanupTimeLabel = useCallback(
    (minutes: number, isPreptime: boolean) => {
      // Show hour
      if (minutes >= 60) {
        const numHours = getCommaFormattedNumber(minutes / 60, 1);
        return isPreptime
          ? gettextCatalog.getPlural(
              minutes / 60,
              '1h. preparation',
              '{{ numHours }}h. preparation',
              {
                numHours,
              }
            )
          : gettextCatalog.getPlural(
              minutes / 60,
              '1h. clean up',
              '{{ numHours }}h. clean up',
              { numHours }
            );
      } else {
        // Show minutes
        return isPreptime
          ? gettextCatalog.getString('{{ amount }} min. preparation', {
              amount: minutes,
            })
          : gettextCatalog.getString('{{ amount }} min. clean up', {
              amount: minutes,
            });
      }
    },
    []
  );

  return (
    <StyledEventListItem>
      <div className="date-box">
        <div className="date-box__day">
          {moment(event.startDate).format('DD')}
        </div>
        <div className="date-box__month">
          {moment(event.startDate).format('MMM')}
        </div>
      </div>
      <div className="u-flex u-align-items-center">
        <StyledDescriptions
          title={
            <CdTooltip title={gettextCatalog.getString('View in a new tab')}>
              <a
                className="u-flex u-align-items-center u-mb-0i text-md showIcon"
                style={{ width: 'fitContent' }}
                onClick={() => {
                  let url: string;
                  if (isFromOtherChurchDesk(event)) {
                    // Do not open the event in a new tab if it's from another ChurchDesk.
                    return;
                  } else {
                    url = state.href(`app.private.calendar.${event.type}`, {
                      id: event.id,
                    });
                  }
                  window.open(url, '_blank');
                }}
              >
                {isFeed(event) ? (
                  <FontAwesomeIcon icon={faRss} className="u-mr-5" />
                ) : null}
                {isAbsence(event) ? (
                  <FontAwesomeIcon icon={faCalendarTimes} className="u-mr-5" />
                ) : null}
                <b>
                  {isFromOtherChurchDesk(event)
                    ? gettextCatalog.getString('Busy')
                    : event.title}
                </b>
              </a>
            </CdTooltip>
          }
          size="small"
          column={1}
          colon={false}
        >
          <Descriptions.Item
            style={{ whiteSpace: 'nowrap', display: 'flex' }}
            label={
              <CdTooltip
                title={gettextCatalog.getString('Event date and time')}
              >
                <FontAwesomeIcon icon={faClock} className="u-mr-5 text-muted" />
              </CdTooltip>
            }
          >
            <div style={{ whiteSpace: 'initial' }}>
              <DisplayDate
                start={event.startDate}
                end={event.endDate}
                allDay={event.allDay}
                showEndTime={!event.hideEndTime}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {prepTimeMinutes > 0 ? (
                  <Space className="u-text-italic u-text-muted">
                    + {getCleanupTimeLabel(prepTimeMinutes, true)}
                  </Space>
                ) : null}
                {cleanupTimeMinutes > 0 ? (
                  <Space className="u-text-italic u-text-muted">
                    + {getCleanupTimeLabel(cleanupTimeMinutes, false)}
                  </Space>
                ) : null}
              </div>
            </div>
          </Descriptions.Item>
          {isRepeatedEvent(event) && !_.isEmpty(childEvents) ? (
            <Descriptions.Item
              style={{ display: 'flex' }}
              label={
                <CdTooltip title={gettextCatalog.getString('Event repeats')}>
                  <FontAwesomeIcon
                    icon={faRedo}
                    className="u-mr-5 text-muted"
                  />
                </CdTooltip>
              }
            >
              <span>
                {gettextCatalog.getString(
                  '{{count}} more events are conflicting in the repeated series',
                  {
                    count: _.size(childEvents),
                  }
                )}
              </span>
              <Popover
                title={gettextCatalog.getString(
                  'Conflicting events in the series'
                )}
                placement="right"
                trigger="click"
                getPopupContainer={() =>
                  document.getElementById('booking-conflicting-modal')
                }
                overlayStyle={{ width: '70%' }}
                content={
                  <div
                    style={{
                      overflowY: 'scroll',
                      maxHeight: '50vh',
                      padding: '16px 16px 0px',
                    }}
                  >
                    <ChildEventsComponent
                      childEvents={childEvents}
                      organization={organization}
                    />
                  </div>
                }
              >
                <Button style={{ display: 'block' }} type="link">
                  {gettextCatalog.getString('Show events')}
                </Button>
              </Popover>
            </Descriptions.Item>
          ) : null}
          {isFromOtherChurchDesk(event) ? (
            <Descriptions.Item
              label={
                <CdTooltip
                  title={gettextCatalog.getString(
                    'Event comes from another ChurchDesk'
                  )}
                >
                  <FontAwesomeIcon
                    icon={faChurch}
                    className="u-mr-5 text-muted"
                  />
                </CdTooltip>
              }
            >
              {event.organization.name}
            </Descriptions.Item>
          ) : null}
        </StyledDescriptions>
      </div>
    </StyledEventListItem>
  );
};

const ConflictingEventsComponent: FunctionComponent<{
  conflictingEvents: DetailedEvent[];
  organization: any;
}> = ({ conflictingEvents, organization }) => {
  if (_.isEmpty(conflictingEvents)) return null;
  const events: ConflictingEvents = parseConflictingEvents(conflictingEvents);
  return (
    <>
      {_.map(
        events,
        ({ event, childEvents }): React.ReactElement => (
          <div style={{ marginLeft: 35 }} key={`div-event-${event.id}`}>
            <EventComponent
              key={`event-${event.id}`}
              event={event}
              childEvents={childEvents}
              organization={organization}
            />
          </div>
        )
      )}
    </>
  );
};

const WorkPlanConflict: FunctionComponent<{ workplan: string[] }> = ({
  workplan,
}) => {
  if (_.isEmpty(workplan)) return null;
  return (
    <div>
      <div className="u-mb-10 u-ml-35">
        <small>{gettextCatalog.getString('From workplan')}</small>
      </div>
      {_.uniq(workplan).map(
        (workPlanName: string): React.ReactElement => (
          <StyledEventListItem key={workPlanName}>
            <div className="date-box">
              <FontAwesomeIcon icon={faBook} />
            </div>
            <div className="u-flex u-align-items-center">
              <StyledDescriptions
                title={
                  <small className="u-flex u-align-items-center u-mb-0i">
                    <b>{workPlanName}</b>
                  </small>
                }
                size="small"
                column={1}
                colon={false}
              ></StyledDescriptions>
            </div>
          </StyledEventListItem>
        )
      )}
    </div>
  );
};

const BookingConflict: FunctionComponent<BookingConflictProps> = ({
  conflicts,
  organization,
}) => {
  const userCanAllowOverbooking: boolean =
    AuthService.hasPermission('canDoubleBook');
  const { users, resources, workplan } = conflicts;
  const userIds = _.keys(users).concat(_.keys(workplan));
  const resourceIds = _.keys(resources);
  return (
    <>
      <div className="u-mb-10">
        <span>
          {gettextCatalog.getString(
            'We found one or more conflicts with your bookings.'
          )}
        </span>{' '}
        <span>
          {userCanAllowOverbooking
            ? gettextCatalog.getString(
                'Would you like to allow the conflicts to be saved anyway or go back and edit?'
              )
            : gettextCatalog.getString('Please go back and edit.')}
        </span>
      </div>
      <div
        id="booking-conflicting-modal"
        style={{ maxHeight: '55vh', display: 'block', overflowY: 'scroll' }}
      >
        <Tabs type="card">
          {!_.isEmpty(users) || !_.isEmpty(workplan) ? (
            <TabPane
              tab={
                <>
                  <FontAwesomeIcon
                    size="lg"
                    color="#e74c3c"
                    icon={faUsers}
                    className="u-mr-10"
                  />
                  <Title
                    level={4}
                    style={{ display: 'inline-block', margin: 0 }}
                  >
                    {gettextCatalog.getPlural(
                      _.size(users),
                      '1 user conflicting',
                      '{{ count }} users conflicting',
                      {
                        count: _.size(users),
                      }
                    )}
                  </Title>
                </>
              }
              key="1"
            >
              <section>
                <Collapse
                  defaultActiveKey={_.head(userIds)}
                  bordered={false}
                  style={{ background: 'none' }}
                  expandIconPosition="end"
                >
                  {_.uniq(userIds).map((key: string): React.ReactElement => {
                    const user: any = (users && users[key]) || {};
                    const workplanConflict: any =
                      (workplan && workplan[key]) || {};
                    return user ? (
                      <Panel
                        header={
                          <div>
                            <Avatar
                              icon={<FontAwesomeIcon icon={faUser} />}
                              size="small"
                              className="u-mr-10"
                            ></Avatar>
                            {user.name}
                          </div>
                        }
                        key={key}
                      >
                        <ConflictingEventsComponent
                          key={`user-${key}`}
                          conflictingEvents={user.data}
                          organization={organization}
                        />
                        <WorkPlanConflict
                          key={`workplan-${key}`}
                          workplan={workplanConflict.workType}
                        />
                      </Panel>
                    ) : null;
                  })}
                </Collapse>
              </section>
            </TabPane>
          ) : null}
          {!_.isEmpty(resources) ? (
            <TabPane
              tab={
                <>
                  <FontAwesomeIcon
                    size="lg"
                    color="#e74c3c"
                    icon={faMapMarkerAlt}
                    className="u-mr-10"
                  />
                  <Title
                    level={4}
                    style={{ display: 'inline-block', margin: 0 }}
                  >
                    {gettextCatalog.getPlural(
                      _.size(resources),
                      '1 resource conflicting',
                      '{{ count }} resources conflicting',
                      {
                        count: _.size(resources),
                      }
                    )}
                  </Title>
                </>
              }
              key="2"
            >
              <section>
                <Collapse
                  defaultActiveKey={_.head(resourceIds)}
                  bordered={false}
                  style={{ background: 'none' }}
                  expandIconPosition="end"
                >
                  {_.map(resourceIds, (key: string): React.ReactElement => {
                    const resource = resources[key];
                    return resource ? (
                      <Panel
                        header={
                          <div>
                            <Avatar
                              icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                              size="small"
                              className="u-mr-10"
                            ></Avatar>
                            {resource.name}
                          </div>
                        }
                        key={key}
                        style={{ border: 'none' }}
                      >
                        <ConflictingEventsComponent
                          key={`resource-${key}`}
                          conflictingEvents={resource.data}
                          organization={organization}
                        />
                      </Panel>
                    ) : null;
                  })}
                </Collapse>
              </section>
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    </>
  );
};

export default BookingConflict;
