import React from 'react';
import { Space, Typography } from 'antd';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import {
  CdHoursSaving,
  CdHoursSaved,
  CdExclamationTriangleIcon,
} from '@/react/shared/components/Icons';

export enum AutoSaveState {
  Saving = 'saving',
  Saved = 'saved',
  Failed = 'failed',
}

const state: Record<
  AutoSaveState,
  { tooltip: string; text: string; icon: React.ReactNode }
> = {
  [AutoSaveState.Saving]: {
    tooltip: gettextCatalog.getString('Saving...'),
    text: 'Saving',
    icon: (
      <CdHoursSaving
        animation="spin"
        type="secondary"
        style={{ fontSize: 20 }}
      />
    ),
  },
  [AutoSaveState.Saved]: {
    tooltip: gettextCatalog.getString('Saved successfully'),
    text: 'Saved',
    icon: (
      <CdHoursSaved
        type="secondary"
        style={{ fontSize: 20, display: 'flex' }}
      />
    ),
  },
  [AutoSaveState.Failed]: {
    tooltip: gettextCatalog.getString('Failed to save'),
    text: 'Failed',
    icon: (
      <CdExclamationTriangleIcon type="secondary" style={{ fontSize: 20 }} />
    ),
  },
};

export const AutoSaveStateStatus = (props: { saveState: AutoSaveState }) => (
  <Space align="center">
    <CdTooltip title={state[props.saveState].tooltip}>
      {state[props.saveState].icon}
    </CdTooltip>
    <Typography.Text type="secondary">
      {state[props.saveState].text}
    </Typography.Text>
  </Space>
);
