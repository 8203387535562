import { Space, SpaceProps } from 'antd';
import React from 'react';

export const CdVerticalSpace = (
  props: Pick<SpaceProps, 'size' | 'children' | 'align' | 'style'>
) => (
  <Space
    direction="vertical"
    style={{ width: '100%', ...props.style }}
    size={props.size}
  >
    {props.children}
  </Space>
);
