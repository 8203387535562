import { useCallback } from 'react';

import { RotaScheme } from '@/react/calendar/rota-scheme/types/rotaScheme.types';
import {
  useDeleteRotaSchemaMutation,
  useRefreshRotaSchemes,
} from '@/react/calendar/rota-scheme/store/rotaHooks';
import NotificationService from '@/react/services/NotificationService';
import gettextCatalog from '@/react/services/I18nService';
import { openCopyShareableLinkModal } from '@/react/calendar/rota-scheme/CopySherableLinkModal';
import { openEditSettingsModal } from '@/react/calendar/rota-scheme/SchemeSettingsModal';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';

export const useRotaLinkActions = () => {
  const refreshRotas = useRefreshRotaSchemes();
  const { deleteRota } = useDeleteRotaSchemaMutation();
  const copyShareableLink = useCallback(
    async (rotaScheme: RotaScheme) =>
      openCopyShareableLinkModal({
        id: rotaScheme.id,
        url: `${window.cdApp.references.appUrl}/rota/${rotaScheme.id}`,
      }),
    []
  );

  const editSettings = useCallback(
    async (rotaScheme: RotaScheme) => {
      await openEditSettingsModal({
        rotaScheme,
      });
      await refreshRotas();
    },
    [refreshRotas]
  );

  const deleteScheme = useCallback(
    async (rotaScheme: RotaScheme) => {
      await showConfirmModal({
        title: gettextCatalog.getString('Delete rota scheme'),
        message: gettextCatalog.getString(
          'Do you want to delete the rota scheme?'
        ),
        okText: gettextCatalog.getString('Delete'),
        showIcon: false,
        okButtonProps: {
          danger: true,
        },
        onOk: async () => {
          await deleteRota(
            { rotaScheme },
            {
              onSuccess: async () => {
                NotificationService.notifySuccess(
                  gettextCatalog.getString('Rota scheme deleted successfully')
                );
                return refreshRotas();
              },
            }
          );
        },
      });
    },
    [deleteRota, refreshRotas]
  );

  return {
    copyShareableLink,
    editSettings,
    deleteScheme,
  };
};
