import { useCallback, useEffect, useState } from 'react';

import { InnerModalProps } from '@/react/shared/components/cd-modal-new/CdModal2';
import { mainApi } from '@/react/api';
import {
  checkAddressUpdateStatus,
  sleep,
} from '@/react/safeguarding/common/Helpers';
import { gettextCatalog } from '@/react/services/I18nService';
import {
  QueuedUserUpdate,
  SafeguardingUserUploadPayload,
  SafeguardingUserUploadResponse,
} from '@/react/safeguarding/types/SafeguardingTypes';
import { getCountryIso2 } from '@/react/services/locale/country.service';

export const useBulkImportSafeguardingUsers = ({
  rows,
}: {
  setModalProps: ((props: InnerModalProps) => void) | undefined;
  rows: QueuedUserUpdate[];
}) => {
  const [queue, setQueue] = useState<{
    current: number;
    rows: QueuedUserUpdate[];
  }>({
    current: 0,
    rows,
  });

  const processing: boolean =
    queue.rows.length > 0 && queue.current < queue.rows.length;

  const handleQueue = useCallback(async () => {
    if (queue.current < queue.rows.length) {
      let currentUser = queue.rows[queue.current];
      const errors: string[] = currentUser.errors;
      const userInput = currentUser.data;
      let addressResponse;

      if (errors.length === 0) {
        let safeguarding;
        if (
          !currentUser.data?.safeguardRequirement &&
          !currentUser.data?.type &&
          !currentUser.data?.workAndResponsibilities
        ) {
          safeguarding = undefined;
        } else {
          safeguarding = {
            safeguardRequirement: currentUser.data?.safeguardRequirement,
            type: currentUser.data.type && currentUser.data.type,
            workAndResponsibilities: currentUser.data?.workAndResponsibilities,
            parishIds: [],
          };
        }
        const payload: SafeguardingUserUploadPayload = {
          firstName: currentUser.data?.firstName,
          lastName: currentUser.data?.lastName,
          email: currentUser.data.email,
          status: currentUser.data.status || 'createdWithoutLogin',
          safeguarding: safeguarding,

          address: {
            street: currentUser.data.street,
            city: currentUser.data.city,
            postCode: currentUser.data.postCode,
            countryIso2: getCountryIso2(currentUser.data.country),
          },
        };

        // Update user one by one
        try {
          const response = await mainApi.post<SafeguardingUserUploadResponse>(
            `users`,
            payload
          );
          if (!response.ok) {
            errors.push(response.data.message);
          } else {
            addressResponse = response.data.address;
          }
        } catch (e) {
          errors.push(gettextCatalog.getString('Unable to create a user'));
        }

        // Sleep 200ms for less load
        await sleep(200);
      }
      const addressUpdateStatus = checkAddressUpdateStatus({
        userInput,
        resAddress: addressResponse,
      });

      // Clone is required to re-render table
      currentUser = {
        ...currentUser,
        addressUpdateStatus: addressUpdateStatus,
        errors,
        state: errors.length > 0 ? 'failed' : 'success',
      };

      // Clone is required to re-render table
      const rowsClone = [...queue.rows];
      rowsClone[queue.current] = currentUser;

      // triggers run for next row
      setQueue({
        current: queue.current + 1,
        rows: rowsClone,
      });
    }
  }, [queue]);

  useEffect(() => {
    void handleQueue();
  }, [handleQueue]);

  return {
    processing,
    queue,
  };
};
