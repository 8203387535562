import { Button, Form, InputNumber, Select, Typography } from 'antd';
import React from 'react';

import { useIntentionSettings } from './hooks/use-intention-settings';

import { gettextCatalog } from '@/react/services/I18nService';
import { getString } from '@/react/services/GetStringX';
import {
  integerFormat,
  positiveNumberFormat,
} from '@/react/shared/antd/validations/common';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

export const IntentionSettings = () => {
  const { settings, onFinish } = useIntentionSettings();
  const isIntentionMigrationReferenceNumberAlreadySet =
    settings.intentionMigrationReferenceNumber ? true : false;
  return (
    <Form
      layout="vertical"
      initialValues={settings}
      style={{ maxWidth: '400px' }}
      onFinish={onFinish}
    >
      <Typography.Paragraph>
        {settings?.intentionReferenceNumberType === 'parishSeries'
          ? gettextCatalog.getString(
              'All intentions created will have an unique number by parish.'
            )
          : gettextCatalog.getString(
              'All intentions created will have an unique number throughout the organization starting from {{year}}',
              { year: settings?.intentionReferenceInEffectFrom }
            )}
      </Typography.Paragraph>
      <CdTooltip
        title={
          !settings?.canConfigureIntentionReferenceNumberType &&
          gettextCatalog.getString(
            'Not possible to change since there is already intentions in the current year and the following year.'
          )
        }
      >
        <Form.Item
          label={gettextCatalog.getString('Intention number unique by')}
          name="intentionReferenceNumberType"
        >
          <Select
            options={[
              {
                value: 'parishSeries',
                label: gettextCatalog.getString('Parish'),
              },
              {
                value: 'orgSeries',
                label: gettextCatalog.getString('Organization'),
              },
            ]}
            disabled={!settings?.canConfigureIntentionReferenceNumberType}
          />
        </Form.Item>
      </CdTooltip>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues?.intentionReferenceNumberType !==
          currentValues?.intentionReferenceNumberType
        }
      >
        {({ getFieldValue }) => {
          const intentionReferenceNumberType = getFieldValue(
            'intentionReferenceNumberType'
          );
          return (
            <CdTooltip
              title={
                intentionReferenceNumberType === 'parishSeries'
                  ? getString(
                      'This is managed from <i>Parish & Resources</i> when <i>Intention number unique by</i> is set to Parish.',
                      {
                        i: (text) => `<i style="margin-right:4px;">${text}</i>`,
                      }
                    )
                  : !settings?.canConfigureIntentionMigrationReferenceNumber &&
                    getString(
                      "It's not possible to specify the starting intention number since there are already intentions in the system."
                    )
              }
            >
              <Form.Item
                label={gettextCatalog.getString('Intention number starts from')}
                name="intentionMigrationReferenceNumber"
                rules={[integerFormat, positiveNumberFormat]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  disabled={
                    !settings?.canConfigureIntentionMigrationReferenceNumber ||
                    intentionReferenceNumberType === 'parishSeries' ||
                    isIntentionMigrationReferenceNumberAlreadySet
                  }
                />
              </Form.Item>
            </CdTooltip>
          );
        }}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
