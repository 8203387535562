import { Button, Checkbox, Col, Form, Row, Skeleton, Space } from 'antd';
import React from 'react';

import { UponCloseInterface } from '../hooks/use-close-modal';
import { CustomTabs } from '../EventDetailsPage';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { FieldSection } from './FieldSection';
import { FormItemNoErrorMessage } from './form-items/FormItemNoErrorMessage';

import {
  MINIMUM_COLUMN_WIDTH,
  MINIMUM_SECOND_COLUMN_WIDTH,
} from '@/react/calendar/event-details/constants';
import { gettextCatalog } from '@/react/services/I18nService';
import { EventIcons } from '@/react/shared/components/Icons';

const SkeletonInput = (props) => (
  <Skeleton.Input {...props} active style={{ width: '100%' }} block />
);

export const EventDetailsPageSuspended = (props: {
  close?: (props?: UponCloseInterface) => Promise<void>;
}) => {
  const size = 'large';
  return (
    <Form layout="vertical">
      <CdVerticalSpace size={24}>
        <Row gutter={16}>
          <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
            <CdVerticalSpace size={24}>
              <FieldSection>
                <FormItemNoErrorMessage>
                  <Skeleton.Input
                    {...props}
                    size="large"
                    active
                    style={{ width: '100%' }}
                    block
                  />
                </FormItemNoErrorMessage>
              </FieldSection>
              <FieldSection>
                <Space direction="vertical">
                  <Form.Item name="date" noStyle>
                    <Skeleton.Input
                      {...props}
                      active
                      style={{ width: '300px' }}
                    />
                  </Form.Item>
                  <Space direction="horizontal">
                    <Checkbox disabled>
                      {gettextCatalog.getString('All day')}
                    </Checkbox>
                    <Skeleton.Input
                      {...props}
                      active
                      style={{ width: '100px' }}
                    />
                  </Space>
                </Space>
              </FieldSection>
            </CdVerticalSpace>
          </Col>
          <Col flex="auto">
            <Space style={{ width: '100%', marginLeft: '24px' }}>
              <Button disabled type="primary" size={size}>
                {gettextCatalog.getString('Save')}
              </Button>
              <Button size={size} disabled icon={<EventIcons.Notify />}>
                {gettextCatalog.getString('Save & Notify')}
              </Button>
              {props.close && (
                <Button size={size} onClick={() => props.close()}>
                  {gettextCatalog.getString('Cancel')}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
        <CustomTabs
          defaultActiveKey="1"
          style={{ marginTop: '8px' }}
          type="card"
          items={[
            {
              label: gettextCatalog.getString('Event details'),
              key: '1',
              forceRender: true,
              children: (
                <Row gutter={16}>
                  <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
                    <FieldSection>
                      <Form.Item
                        label={gettextCatalog.getString('Resources & location')}
                      >
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>
                    <FieldSection>
                      <Form.Item>
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>

                    <FieldSection>
                      <Form.Item
                        label={gettextCatalog.getString('Contributor')}
                      >
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>

                    <FieldSection>
                      <Form.Item label={gettextCatalog.getString('Image')}>
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>

                    <FieldSection>
                      <Form.Item
                        label={gettextCatalog.getString(
                          'Event short description'
                        )}
                      >
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>
                    <FieldSection>
                      <Form.Item
                        label={gettextCatalog.getString(
                          'Public event description'
                        )}
                      >
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>
                    <FieldSection>
                      <Form.Item label={gettextCatalog.getString('Channels')}>
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>
                    <FieldSection>
                      <Form.Item label={gettextCatalog.getString('Price')}>
                        <SkeletonInput />
                      </Form.Item>
                    </FieldSection>
                  </Col>
                  <Col flex={`${MINIMUM_SECOND_COLUMN_WIDTH + 16}px`}>
                    <Row style={{ marginLeft: '24px' }}>
                      <Col flex="auto">
                        <Form.Item
                          label={gettextCatalog.getString('Book users')}
                        >
                          <SkeletonInput />
                        </Form.Item>

                        <Space direction="vertical">
                          <SkeletonInput />
                          <SkeletonInput />
                          <SkeletonInput />
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
            {
              label: gettextCatalog.getString('Rota', undefined, 'Event form'),
              key: '2',
            },
            {
              label: gettextCatalog.getString('Sign-ups'),
              key: '3',
            },
            {
              label: gettextCatalog.getString('Attendance'),
              key: '4',
            },
            {
              label: gettextCatalog.getString('Comments'),
              key: '5',
            },
            {
              label: gettextCatalog.getString('Version history'),
              key: '6',
            },
          ]}
        />
      </CdVerticalSpace>
    </Form>
  );
};
