import React, { useEffect } from 'react';

import { createCdDrawer } from '../../shared/components/cd-drawer/CdDrawer';
import { gettextCatalog } from '../../services/I18nService';
import { ModalType } from '../../shared/components/cd-modal-new/CdModal2';

import { EditSafeguardingSuspended } from './EditSafeguardingSuspended';
import { SafeguardingForm } from './SafeguardingForm';

export const showSafeguardingDrawer = createCdDrawer<{
  isEdit: boolean;
  currentUserId: number;
  currentUserOrganizationStatus: string;
}>((props) => {
  useEffect(() => {
    props.setDrawerProps({
      title: gettextCatalog.getString('Documents and certificates'),
      width: 1000,
      fallback: <EditSafeguardingSuspended />,
      modalType: ModalType.POPUP,
      hideCancel: true,
      hideOk: true,
    });
  }, [props]);

  return (
    <SafeguardingForm
      isEdit={props.isEdit}
      currentUserId={props.currentUserId}
      currentUserOrganizationStatus={props.currentUserOrganizationStatus}
      closeDrawer={props.closeDrawer}
    />
  );
});
