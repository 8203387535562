import React from 'react';
import { Space, Button } from 'antd';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { RotaSchemeTable } from '@/react/calendar/rota-scheme/RotaSchemeTable';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import gettextCatalog from '@/react/services/I18nService';

export const RotaSchemePage = () => (
  <CdPage
    pageHeaderProps={{
      title: gettextCatalog.getString('Rota schemes'),
      subTitle: gettextCatalog.getString('Manage and share your rota schemes'),
      extra: (
        <Space>
          <Button
            type="primary"
            href={getStateUrl('app.private.calendar.rotas.new')}
          >
            {gettextCatalog.getString('New rota scheme')}
          </Button>
          <Button href={getStateUrl('app.private.intranet.groups')}>
            {gettextCatalog.getString('View rota groups')}
          </Button>
        </Space>
      ),
    }}
  >
    <RotaSchemeTable />
  </CdPage>
);
