import { Form, Switch, Alert, Row, Col, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { gettextCatalog } from '../../services/I18nService';
import { TimeRegistrationHoursInput } from '../time-registration/TimeRegistrationHoursInput';

import { CdWarningIcon } from '@/react/shared/components/Icons';
import { required } from '@/react/shared/antd/validations/common';
import cdApp from '@/react/config';
import { CdMasqueradeTag } from '@/react/shared/components/CdMasqueradeTag';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

export interface TimeRegistrationUserSettingsProps {
  noOfEnabledTimeRegistrations: number;
  timeRegistrationCap: number;
  initialTimeBalance?: number;
  hasNoSeatsLeft?: boolean;
  removeMasqueradeIcon?: boolean;
}

export const TimeRegistrationUserSettings = (
  props: TimeRegistrationUserSettingsProps
) => (
  <>
    {props.hasNoSeatsLeft && props.timeRegistrationCap > 0 && (
      <Alert
        type="warning"
        message={gettextCatalog.getString(
          'You have reached the maximum number of users with time registration ({{ max }}). Please contact ChurchDesk if you are interested in more seats.',
          { max: props.timeRegistrationCap }
        )}
        icon={
          <CdWarningIcon
            type="warning"
            style={{ marginRight: 16, fontSize: 24 }}
          />
        }
        showIcon
        style={{ marginBottom: 16 }}
      />
    )}
    <Row justify="space-between" wrap={false} gutter={16}>
      <Col>
        <FormItemLabel
          label={
            <Space>
              {gettextCatalog.getString('Enable time registration')}
              {props.timeRegistrationCap === 0 && (
                <Typography.Text type="secondary">
                  {gettextCatalog.getString('(Not included in your plan)')}
                </Typography.Text>
              )}
            </Space>
          }
        />
      </Col>
      <Col>
        <Form.Item
          name={['timeRegistrationEnabled']}
          valuePropName="checked"
          noStyle
        >
          <Switch disabled={props.hasNoSeatsLeft} />
        </Form.Item>
      </Col>
    </Row>
    <Form.Item
      shouldUpdate={(prevValues, currentValues) =>
        prevValues?.timeRegistrationEnabled !==
        currentValues?.timeRegistrationEnabled
      }
    >
      {({ getFieldValue }) => {
        const timeRegistrationEnabled = getFieldValue(
          'timeRegistrationEnabled'
        );

        return (
          <Row justify="space-between" wrap={false} gutter={16}>
            <Col>
              <FormItemLabel
                label={
                  <Space>
                    {gettextCatalog.getString('Initial time balance')}
                    {props.timeRegistrationCap === 0 && (
                      <Typography.Text type="secondary">
                        {gettextCatalog.getString(
                          '(Not included in your plan)'
                        )}
                      </Typography.Text>
                    )}
                  </Space>
                }
                extra={gettextCatalog.getString(
                  'You can use negative numbers when required. This cannot be changed. But the single user can register negative hours to adjust their balance.'
                )}
                required
              />
            </Col>
            <Col>
              <Form.Item
                name={['initialTimeBalance']}
                rules={timeRegistrationEnabled && [required()]}
                extra={
                  cdApp.me.masquerading &&
                  !props.removeMasqueradeIcon && (
                    <CdTooltip title="Only masquerading ChurchDesk Employees can edit this field. The user themselves are not allowed to change their balance.">
                      <CdMasqueradeTag />
                    </CdTooltip>
                  )
                }
              >
                <TimeRegistrationHoursInput
                  disabled={
                    (props.initialTimeBalance !== undefined ||
                      timeRegistrationEnabled === false ||
                      props.hasNoSeatsLeft) &&
                    !cdApp.me.masquerading
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  </>
);

const FormItemLabel = styled(Form.Item)`
  .ant-form-item-control-input {
    display: none;
  }
`;
