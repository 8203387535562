import { List } from '../components/MesssageSettings';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const reSubscribeToList = async ({
  listId,
  personId,
}): Promise<List> => {
  const response = await mainApi.put<any>(
    `people/people/${personId}/re-subscribe`,
    {
      segmentIds: [listId],
    }
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};
