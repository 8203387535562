'use strict';

import I18nService from '@/react/services/I18nService';

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    const titleTasks = (gettext, gettextCatalog, routingHelpers) => {
      'ngInject';

      const base = gettext('Rotas');
      return {
        base,
        rendered: routingHelpers.buildTitle(gettextCatalog.getString(base)),
      };
    };
    titleTasks.$inject = ['gettext', 'gettextCatalog', 'routingHelpers'];

    $stateProvider
      .state('app.private.intranet.shifts', {
        abstract: true,
        url: '/my-rotas',
        template: `
          <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.intranet.shifts.default', {
        url: '',
        component: 'cdrProductLandingPage',

        params: {
          errorCode: null,
          packageName: 'rotas',
        },

        resolve: {
          $title: ['gettext', 'gettextCatalog', 'routingHelpers', titleTasks],
          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },

        onEnter: [
          '$state',
          'errorCode',
          function ($state, errorCode) {
            if (!errorCode) {
              return $state.target('app.private.calendar.myRotas');
            }
          },
        ],
      })
      .state('app.private.calendar.myRotas', {
        url: '/my-rotas?{pageMyRotas:int}&{pageOpenRotas:int}&{search:string}&{period:string}&{orderBy:string}&{desc:bool}',
        component: 'cdManageShiftsState',
        params: {
          pageMyRotas: {
            squash: false,
            dynamic: true,
          },

          pageOpenRotas: {
            squash: false,
            dynamic: true,
          },

          search: {
            value: '',
            squash: true,
            dynamic: true,
          },

          period: {
            value: 'upcoming',
            squash: true,
            dynamic: true,
          },

          orderBy: {
            value: 'startDate',
            squash: true,
            dynamic: true,
          },

          desc: {
            value: false,
            squash: true,
            dynamic: true,
          },
        },

        resolve: {
          $title: ['gettext', 'gettextCatalog', 'routingHelpers', titleTasks],
        },

        onEnter: [
          '$state',
          'Authorization',
          function ($state, Authorization) {
            if (!Authorization.hasPackage('planning')) {
              return $state.target('app.private.intranet.shifts.default', {
                errorCode: 'PACKAGE_NOT_ENABLED',
              });
            }
            if (!Authorization.hasPermission('canAccessPlanning')) {
              return $state.target('app.private.intranet.shifts.default', {
                errorCode: 'ACCESS_DENIED',
              });
            }
          },
        ],
      })
      .state('app.public.selfUnassignFromRotas', {
        url: '/self-unassign-from-rotas?{userId:int}&{eventId:int}&{organizationId:int}&{token:string}&{language:string}',
        component: 'cdSelfUnassignFromRotasState',
        onEnter: [
          '$stateParams',
          'gettextCatalog',
          'Me',
          function ($stateParams, gettextCatalog, Me) {
            /**
             * Wait for the Me endpoint to be resolved in order to get the organization's language if the user
             * is not logged in. Otherwise, it will be set from the user's browser
             * @see app.config.js
             */
            return Me.catch(() =>
              I18nService.setCurrentLanguage($stateParams.language)
            );
          },
        ],
      });
  },
]);
