import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventApi } from '@fullcalendar/react';
import { Popover, PopoverProps } from 'antd';
import moment from 'moment';
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';

import { FEvent, Type, convertTypeToEventType } from '../models/calendar';
import { gettextCatalog } from '../../services/I18nService';
import {
  AbsenceSelectors,
  useToggleOpenedAbsencePopover,
} from '../store/absences/absencesSlice';

import { EventPopoverContent } from './EventPopoverContent';
import { GroupedAbsencesPopoverContent } from './GroupedAbsencesPopoverContent';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

export const AbsenceDescription: FunctionComponent<{ absence: FEvent }> = ({
  absence,
}) => {
  const isAllDay = absence.allDay;
  const isSingleDay =
    moment(absence.endDate).diff(absence.startDate, 'days') === 0;
  const allDayString = gettextCatalog.getString('(all day)');

  if (isSingleDay) {
    if (isAllDay) {
      return (
        <span>{`${moment(absence.startDate).format(
          'dddd, LL'
        )} ${allDayString}`}</span>
      );
    }

    // Not all day
    const date = moment(absence.startDate).format('dddd, LL');
    const startTime = moment(absence.startDate).format('LT');
    const endTime = moment(absence.endDate).format('LT');
    return (
      <div>
        {date} {startTime} - {endTime}
      </div>
    );
  } else {
    if (isAllDay) {
      const startDate = moment(absence.startDate).format('LL');
      const endDate = moment(absence.endDate).format('LL');
      return <span>{`${startDate} - ${endDate} ${allDayString}`}</span>;
    }

    // Not all day
    const startDate = moment(absence.startDate).format('LLL');
    const endDate = moment(absence.endDate).format('LLL');
    return <span>{`${startDate} - ${endDate}`}</span>;
  }
};

export function getAbsenceActions(
  absence: FEvent,
  startEditEvent: () => void,
  startDeleteEvent: () => void
): ReactNode[] {
  const actions: ReactNode[] = [];
  if (absence.canEdit) {
    actions.push(
      <CdTooltip title={gettextCatalog.getString('Edit absence')}>
        <a key="list-edit" onClick={startEditEvent} className="text-muted">
          <FontAwesomeIcon icon={faPencilAlt} />
        </a>
      </CdTooltip>
    );
  }
  if (absence.canDelete) {
    actions.push(
      <CdTooltip title={gettextCatalog.getString('Delete absence')}>
        <a key="list-delete" onClick={startDeleteEvent} className="text-muted">
          <FontAwesomeIcon icon={faTrashAlt} />
        </a>
      </CdTooltip>
    );
  }

  return actions;
}

interface Props {
  event: EventApi;
  popoverIndex: string;
}

const CalendarEventPopover: FunctionComponent<PropsWithChildren<Props>> = ({
  event,
  children,
  popoverIndex,
}) => {
  const setIsToolTipVisible = useToggleOpenedAbsencePopover();
  const isToolTipVisible = useSelector(
    AbsenceSelectors.isGroupedAbsenceOpened(popoverIndex)
  );
  const absencesIds: number[] = event.extendedProps.ids;

  const closeToolTip = useCallback(
    (e?) => {
      e?.preventDefault();
      e?.stopPropagation();
      setIsToolTipVisible(false);
    },
    [setIsToolTipVisible]
  );

  const stopPropagation = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  let content = null;
  if (isToolTipVisible) {
    content = event.extendedProps.isMinimizedAbsences ? (
      <GroupedAbsencesPopoverContent
        closeToolTip={closeToolTip}
        stopPropagation={stopPropagation}
        event={event}
        absencesIds={absencesIds}
      />
    ) : (
      <EventPopoverContent
        eventType={convertTypeToEventType(event.extendedProps.type as Type)}
        closeToolTip={closeToolTip}
        stopPropagation={stopPropagation}
        eventId={parseInt(event.id)}
      />
    );
  }

  const PopoverProps: PopoverProps = {
    id: 'calendar-event-popover',
    title: false,
    content,
    arrow: { pointAtCenter: true },
    placement: 'left',
    trigger: 'click',
    autoAdjustOverflow: true,
    overlayStyle: {
      padding: 0,
      backgroundColor: 'transparent',
    },
    overlayInnerStyle: { padding: '0px' },
    overlayClassName: 'event-popover-generic',
    open: isToolTipVisible,
    destroyTooltipOnHide: true,
    style: { flex: 0 },
    getPopupContainer: () => document.getElementById('calendar-container'),
  };

  // Flex was added to fix: https://app.shortcut.com/churchdesk/story/25941/event-pop-over-opens-out-of-the-screen-in-day-view
  // Removed conditional depending on view type: https://app.shortcut.com/churchdesk/story/26794/pop-over-for-events-absence-that-are-several-days-is-out-of-the-screen-on-the-left-side-in-both-month-and-week
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      <Popover {...PopoverProps} />
      <div style={{ flex: 1, minWidth: 0 }}>{children}</div>
    </div>
  );
};

export default CalendarEventPopover;
