import React from 'react';
import { Space } from 'antd';

import CdCountryFlag from '../cd-country-flag/CdCountryFlag';
import { sortString } from '../../libs/sorter';
import { CdSelect, CdSelectProps } from '../cd-select/CdSelect';

import { getCountryList } from '@/react/services/locale/country.service';

export const CdCountrySelect = (props: Omit<CdSelectProps, 'options'>) => {
  const countries = getCountryList()
    .sort((a, b) => sortString(a.name, b.name))
    .map((country) => ({
      label: (
        <Space>
          <CdCountryFlag
            countryIso2={country.alpha2}
            style={{ fontSize: '0.8rem' }}
          />
          {country.name}
        </Space>
      ),
      value: country.alpha2,
    }));

  return (
    <CdSelect
      options={countries}
      tagRender={(props) => (
        <div className="ant-select-selection-item">
          <span className="ant-select-selection-item-content">
            <CdCountryFlag
              countryIso2={props.value}
              style={{ fontSize: '0.8rem' }}
            />
          </span>
        </div>
      )}
      filterOption={filterIgnoreUppercaseCountries}
      filterSort={undefined}
      {...props}
    />
  );
};

// Necessary because Countries Select has multiple elements as children, rather than just a span.
function filterIgnoreUppercaseCountries(
  input: string,
  option: { value: string; label: any } // eslint-disable-line
): boolean {
  const countryCode = option.value;
  const countryName = option.label.props.children[1];

  const inputIncludesCode = countryCode
    .toLowerCase()
    .includes(input.toLowerCase());
  const inputIncludesName = countryName
    .toLowerCase()
    .includes(input.toLowerCase());

  return inputIncludesCode || inputIncludesName;
}
