import {
  faCalendarTimes,
  faCircle,
  faGlobeAmericas,
  faPlus,
  faRss,
  faUser,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Collapse,
  List,
  Skeleton,
  Row,
  Col,
  Typography,
  Popover,
  Button,
  Space,
} from 'antd';
import {
  chain,
  each,
  filter,
  find,
  get,
  intersection,
  isEmpty,
  isNil,
  keys,
  map,
  mapValues,
  orderBy,
  size,
  take,
  toLower,
  trim,
  drop,
  countBy,
} from 'lodash';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { showModal } from 'redux-saga-modal';
import { useRecoilRefresher_UNSTABLE as useRecoilRefresher } from 'recoil';

import {
  COLOR_INDEX,
  NO_COLOR,
  NO_COLOR_LOADING,
} from '../../../app/ResourceColors';
import {
  Church,
  DetailedEvent,
  EventType,
  FEvent,
  Rota,
  Taxonomies,
  Taxonomy,
  User,
  Visibility,
} from '../models/calendar';
import { getCurrentUserId } from '../../config/store/Selector';
import {
  Intention,
  IntentionPriorityTypes,
  IntentionStatusTypes,
} from '../../intention/models/intention';
import { Stole } from '../../intention/models/stole';
import appUtils from '../../services/AppUtilsService';
import AuthorizationService from '../../services/AuthorizationService';
import { gettextCatalog } from '../../services/I18nService';
import { getStateUrl, navigate } from '../../services/StateServiceFactory';
import {
  getIntentionFees,
  getStoleFeeTaxonomyIds,
} from '../../settings/redux/intention-fees/Selectors';
import { DisplayDate } from '../../shared/components/FormattedDate';
import { getChurches } from '../../shared/store/resources';
import {
  getCommaFormattedNumber,
  handleSuccessMessage,
  unEscape,
} from '../../shared/utils';
import { eventsDetailsHooks } from '../store/events/eventsDetailsSlice';
import { CdRRuleSummary } from '../event-details/components/CdRRuleSummary';
import {
  fullCalendarActions,
  fullCalendarHooks,
} from '../store/events/fullCalendarEventsSlice';
import CreateStoleModal from '../../intention/components/stoles/CreateStoleModal';
import MODAL_TYPES from '../../intention/sagas/modal-types';
import StoleService from '../../intention/services/StoleService';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import { useDispatchToggleIsMinimizedView } from '../store/absences/absencesSlice';
import { EventQuery, useGetEvent } from '../store/events/event';
import { useAnswerToEvent } from '../event-details/hooks/use-answer-to-event';
import { openEventDrawer } from '../event-details/EventDrawer';
import { showPrintModal } from '../event-details/components/PrintModal';

import { PopoverContentCard } from './PopoverContentCard';
import { PopoverActionButtons } from './PopoverActionButtons';
import InvitationResponse from './InvitationResponse';
import FilesSection from './EventPopoverFilesSection';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import {
  CdClose,
  CdCopyIcon,
  CdDeleteIcon,
  CdEditIcon,
  CdEyeIcon,
  CdGroupAbsences,
  CdPrint,
} from '@/react/shared/components/Icons';
import { HasMultipleChurches } from '@/react/user/store/user-session';
import { useFetchEventIntentions } from '@/react/intention/store/intention.store';
import { useFetchEventStole } from '@/react/intention/store/intention-stole.store';
import { orderUsers } from '@/react/organization/services/organization.service';
import { OrganizationUsersIndexed } from '@/react/organization/store/organization';
import { SlimUser } from '@/react/organization/types/organization';

const { Panel } = Collapse;
const { Paragraph, Text } = Typography;

const PopoverTitle = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  font-size: 18px;
  line-height: 1.4;
`;

const getUserName = (user: any): string => {
  if (!user) return gettextCatalog.getString('Anonymous');
  if (user.contact && trim(user.contact.fullName)) {
    return user.contact.fullName;
  } else if (trim(user.name)) {
    return user.name;
  } else if (trim(user.fullName)) {
    return user.fullName;
  } else {
    return trim(user.email) ? user.email : gettextCatalog.getString('Unknown');
  }
};

function getIcon(event: FEvent) {
  if (!event) return null;
  if (
    event.type === EventType.Event &&
    event.visibility === Visibility.Public
  ) {
    return faGlobeAmericas;
  } else if (event.type === EventType.Absence) return faCalendarTimes;
  else if (event.type === EventType.External) return faUserClock;
  else if (event.type === EventType.Feed) return faRss;
  return null;
}

const CategoriesSection: FunctionComponent<{ categories: Taxonomy[] }> = ({
  categories,
}) =>
  categories.length > 0 && categories.length > 5 ? (
    <Collapse
      style={{ width: '100%' }}
      className="cd-collapse-custom-collapse"
      expandIconPosition="end"
      ghost
    >
      <Panel
        header={
          <Text strong>
            {gettextCatalog.getPlural(
              size(categories),
              '1 other category',
              '{{ $count }} other categories',
              {
                $count: size(categories),
              }
            )}
          </Text>
        }
        key="1"
        showArrow={true}
      >
        <div style={{ padding: '0 16px' }}>
          <List
            itemLayout="horizontal"
            dataSource={categories}
            renderItem={(category) => (
              <List.Item key={category.id}>
                <List.Item.Meta
                  title={
                    <>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={
                          'fa-xs u-mr-10' + ` color-${category.color || 0}`
                        }
                      />
                      {category.name}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </Panel>
    </Collapse>
  ) : categories.length > 0 && categories.length <= 5 ? (
    <div className="event-data__section">
      <List
        header={
          <Text strong>
            {gettextCatalog.getPlural(
              size(categories),
              '1 other category',
              '{{ $count }} other categories',
              {
                $count: size(categories),
              }
            )}
          </Text>
        }
        itemLayout="horizontal"
        dataSource={categories}
        renderItem={(category) => (
          <List.Item key={category.id}>
            <List.Item.Meta
              title={
                <>
                  <FontAwesomeIcon
                    icon={faCircle}
                    className={
                      'fa-xs u-mr-10' + ` color-${category.color || 0}`
                    }
                  />
                  <a>{category.name}</a>
                </>
              }
            />
          </List.Item>
        )}
      />
    </div>
  ) : null;

const GroupedResources: FunctionComponent<{ resources: Taxonomy[] }> = ({
  resources,
}) => {
  const churches = useSelector(getChurches);
  const groupedResources = useMemo(
    () =>
      chain(resources)
        .groupBy((resource) => resource.parentResourceId)
        .map((resources, parentResourceId) => {
          if (parentResourceId === 'null') {
            return {
              churchName: gettextCatalog.getString('Not linked to a church'),
              resources: resources,
              key: 'not-linked-to-church',
            };
          } else {
            const church = find(
              churches,
              (church) => church.id === parseInt(parentResourceId)
            );
            return {
              churchName: church.name,
              resources: resources,
              key: church.id,
            };
          }
        })
        .value(),
    [resources, churches]
  );
  return resources && resources.length > 5 ? (
    <Collapse
      className="cd-collapse-custom-collapse"
      expandIconPosition="end"
      ghost
    >
      <Panel
        header={
          <Text strong>
            {gettextCatalog.getPlural(
              size(resources),
              '1 booked resource',
              '{{ $count }} booked resources',
              {
                $count: size(resources),
              }
            )}
          </Text>
        }
        key="panel1"
        showArrow={true}
      >
        {groupedResources.map((resourceGroup) => (
          <div key={resourceGroup.key} style={{ padding: '4px 16px' }}>
            <Text type="secondary">{resourceGroup.churchName}</Text>
            <div style={{ padding: '0 16px' }}>
              <List
                itemLayout="horizontal"
                dataSource={resourceGroup.resources}
                renderItem={(resource) => (
                  <List.Item key={resource.id}>
                    <List.Item.Meta
                      title={
                        <>
                          <FontAwesomeIcon
                            icon={faCircle}
                            className={
                              'fa-xs u-mr-10' + ` color-${resource.color || 0}`
                            }
                          />
                          {resource.name}
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        ))}
      </Panel>
    </Collapse>
  ) : resources.length > 0 && resources.length <= 5 ? (
    <div className="event-data__section">
      <Text strong>
        {gettextCatalog.getPlural(
          size(resources),
          '1 booked resource',
          '{{ $count }} booked resources',
          {
            $count: size(resources),
          }
        )}
      </Text>
      {groupedResources.map((resourceGroup) => (
        <div key={resourceGroup.key} style={{ padding: '4px 16px' }}>
          <Text type="secondary">{resourceGroup.churchName}</Text>
          <List
            itemLayout="horizontal"
            style={{ padding: 0 }}
            dataSource={resourceGroup.resources}
            renderItem={(resource) => (
              <List.Item key={resource.id}>
                <List.Item.Meta
                  title={
                    <>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={
                          'fa-xs u-mr-10' + ` color-${resource.color || 0}`
                        }
                      />
                      {resource.name}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      ))}
    </div>
  ) : null;
};

const ResourcesSection: FunctionComponent<{
  resources: Taxonomy[];
  showChurchSelector: boolean;
}> = ({ resources, showChurchSelector }) =>
  !showChurchSelector ? (
    resources.length > 0 && resources.length > 5 ? (
      <Collapse
        className="event-data__section"
        style={{ width: '100%' }}
        expandIconPosition="end"
        ghost
      >
        <Panel
          header={
            <Text strong>
              {gettextCatalog.getPlural(
                size(resources),
                '1 booked resource',
                '{{ $count }} booked resources',
                {
                  $count: size(resources),
                }
              )}
            </Text>
          }
          key="1"
          showArrow={true}
        >
          <List
            itemLayout="horizontal"
            dataSource={resources}
            renderItem={(resource) => (
              <List.Item key={resource.id}>
                <List.Item.Meta
                  title={
                    <>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={
                          'fa-xs u-mr-10' + ` color-${resource.color || 0}`
                        }
                      />
                      {resource.name}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Panel>
      </Collapse>
    ) : resources.length > 0 && resources.length <= 5 ? (
      <div className="event-data__section">
        <List
          header={
            <Text strong>
              {gettextCatalog.getPlural(
                size(resources),
                '1 booked resource',
                '{{ $count }} booked resources',
                {
                  $count: size(resources),
                }
              )}
            </Text>
          }
          itemLayout="horizontal"
          dataSource={resources}
          renderItem={(resource) => (
            <List.Item key={resource.id}>
              <List.Item.Meta
                title={
                  <>
                    <FontAwesomeIcon
                      icon={faCircle}
                      className={
                        'fa-xs u-mr-10' + ` color-${resource.color || 0}`
                      }
                    />
                    {resource.name}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    ) : null
  ) : (
    <GroupedResources resources={resources} />
  );
const showRemainingChurches = (churches: Church[]) => (
  <ul style={{ padding: '5px 10px', marginBottom: 0, listStyle: 'none' }}>
    {drop(churches).map((church) => (
      <li key={`church-${church.id}`}>{church.name}</li>
    ))}
  </ul>
);
const ChurchesSection: FunctionComponent<{
  churches: Church[];
  showChurchSelector: boolean;
}> = ({ churches, showChurchSelector }) => {
  const noOfChurches = size(churches);
  return (
    <Row wrap={false}>
      {showChurchSelector &&
        map(take(churches, 1), (church) => (
          <Col
            key={church.id}
            flex="none"
            style={{ overflowX: 'hidden', marginRight: 8 }}
          >
            <div
              key={`church-${church.id}`}
              title={church.name}
              style={{
                maxWidth: 320,
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {church.name}
            </div>
          </Col>
        ))}
      {noOfChurches > 1 && (
        <Popover content={showRemainingChurches(churches)}>
          <div key="dots">
            +{' '}
            {gettextCatalog.getPlural(
              noOfChurches - 1,
              '1 parish',
              '{{ $count }} parishes',
              {
                $count: noOfChurches - 1,
              }
            )}
          </div>
        </Popover>
      )}
    </Row>
  );
};

const UsersSection: FunctionComponent<{
  users: User[];
  userAttendanceSummary: string;
  usersIndexed: { [userId: number]: SlimUser };
}> = ({ users, usersIndexed, userAttendanceSummary }) => {
  const usersFiltered = filter(
    users,
    (user) => usersIndexed && !!usersIndexed[user.id]
  );
  return usersFiltered.length > 0 && usersFiltered.length > 5 ? (
    <Collapse
      className="cd-collapse-custom-collapse"
      expandIconPosition="end"
      ghost
    >
      <Panel
        header={
          <>
            <Text strong style={{ display: 'block' }}>
              {gettextCatalog.getPlural(
                size(usersFiltered),
                '1 user invited',
                '{{ $count }} users invited',
                {
                  $count: size(usersFiltered),
                }
              )}
            </Text>
            <Text type="secondary">{userAttendanceSummary}</Text>
          </>
        }
        key="1"
        showArrow={true}
      >
        <div style={{ padding: '0 16px' }}>
          <List
            itemLayout="horizontal"
            dataSource={usersFiltered}
            renderItem={(user) => (
              <ListItemBookedUser user={user} usersIndexed={usersIndexed} />
            )}
          />
        </div>
      </Panel>
    </Collapse>
  ) : usersFiltered.length > 0 && usersFiltered.length <= 5 ? (
    <div className="event-data__section">
      <List
        header={
          <>
            <Text strong style={{ display: 'block' }}>
              {gettextCatalog.getPlural(
                size(usersFiltered),
                '1 user invited',
                '{{ $count }} users invited',
                {
                  $count: size(usersFiltered),
                }
              )}
            </Text>
            <Text type="secondary">{userAttendanceSummary}</Text>
          </>
        }
        itemLayout="horizontal"
        dataSource={usersFiltered}
        renderItem={(user) => (
          <ListItemBookedUser user={user} usersIndexed={usersIndexed} />
        )}
      />
    </div>
  ) : null;
};

const AbsenceUser: FunctionComponent<{
  users: User[];
  navigateToUser: (userId: number) => void;
}> = ({ users, navigateToUser }) => {
  if (users.length === 0) return null;
  const absenceUser = users[0];
  const absenceUserImage =
    absenceUser.image && !isEmpty(absenceUser.image) ? (
      <Avatar size={25} src={absenceUser.image} style={{ marginRight: 8 }} />
    ) : (
      <Avatar
        size={25}
        icon={<FontAwesomeIcon icon={faUser} />}
        style={{ marginRight: 8 }}
      />
    );
  return (
    <div className="event-data__section">
      <div className="event-data__label">
        {gettextCatalog.getString('User')}
      </div>

      <div className="u-flex u-align-items-center u-mt-5">
        {absenceUserImage}
        <div className={'u-ml-5' + !absenceUser.name ? 'u-text-break-all' : ''}>
          <a
            className="event-data__user-link"
            onClick={() => navigateToUser(absenceUser.id)}
          >
            {absenceUser.name}
          </a>
        </div>
      </div>
    </div>
  );
};

const RotasSection: FunctionComponent<{
  event: DetailedEvent;
  usersIndexed: { [userId: number]: SlimUser };
}> = ({ event, usersIndexed }) => {
  const calendarEntry = event.shiftsAndIntentions?.find(
    (calendarEntry) => calendarEntry.calendarId === event.id
  );
  const rotas = orderBy(event.rotas, (rotas) => toLower(get(rotas, 'title')), [
    'asc',
  ]).filter((rota) =>
    calendarEntry.shifts.find((shift) => shift.taskId === rota.taskId)
  );

  const getRotaShift = useCallback(
    (taskId: number) =>
      find(event.shiftsAndIntentions && event.shiftsAndIntentions[0]?.shifts, {
        taskId,
      }),
    [event]
  );
  const getRotaUsers = useCallback(
    (rota: Rota) => {
      const shift = getRotaShift(rota.taskId);
      return orderUsers(
        shift?.users.reduce((accumulator, user) => {
          usersIndexed[user.id] && accumulator.push(usersIndexed[user.id]);
          return accumulator;
        }, [])
      );
    },
    [getRotaShift, usersIndexed]
  );

  const getRotaHeader = useCallback(
    (rota: Rota) => {
      const shiftUsers = getRotaUsers(rota);
      const shiftsNumbers = rota.required
        ? `${shiftUsers.length} / ${rota.required}`
        : shiftUsers.length;
      return `${rota.title} (${shiftsNumbers})`;
    },
    [getRotaUsers]
  );

  if (rotas.length === 0) return null;
  return (
    <div style={{ padding: '8px 0' }}>
      <Text strong style={{ padding: '0 16px' }}>
        {gettextCatalog.getString('Tasks')}
      </Text>
      <Collapse
        className="cd-collapse-custom-collapse"
        expandIconPosition="end"
        ghost
      >
        {rotas.map((rota, index) => (
          <Panel
            header={<Text type="secondary">{getRotaHeader(rota)}</Text>}
            key={index}
          >
            <div style={{ padding: '0 16px' }}>
              <List
                itemLayout="horizontal"
                dataSource={getRotaUsers(rota)}
                locale={{
                  emptyText: gettextCatalog.getString('No one is assigned yet'),
                }}
                renderItem={(user) => (
                  <ListItemBookedUser user={user} usersIndexed={usersIndexed} />
                )}
              />
            </div>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

const IntentionsSection: FunctionComponent<{ eventIntentions: Intention[] }> =
  ({ eventIntentions }) => {
    const getIntentionPriority = useCallback((intention: Intention) => {
      const { status, priority } = intention;
      if (status === IntentionStatusTypes.COMPLETED) {
        return priority;
      } else {
        return priority === IntentionPriorityTypes.PERSOLVIERT
          ? 'erstintention'
          : 'weitere';
      }
    }, []);

    if (!eventIntentions) return null;

    return eventIntentions.length > 5 ? (
      <Collapse
        className="cd-collapse-custom-collapse"
        expandIconPosition="end"
        ghost
      >
        <Panel
          header={
            <div>
              <Text strong>
                {gettextCatalog.getPlural(
                  size(eventIntentions),
                  '1 assigned intention',
                  '{{ $count }} assigned intentions',
                  {
                    $count: size(eventIntentions),
                  }
                )}
              </Text>
            </div>
          }
          key="1"
          showArrow={true}
        >
          <div style={{ padding: '0 16px' }}>
            <List
              itemLayout="horizontal"
              dataSource={eventIntentions}
              renderItem={(intention) => (
                <List.Item key={intention.id}>
                  <List.Item.Meta
                    title={
                      <div className="media-left">
                        <div className="u-flex u-flex-columns u-align-items-center">
                          <span className="u-ml-10">
                            {intention.formattedReferenceNumber}
                          </span>
                          <span className="u-text-cap u-ml-5">
                            {getIntentionPriority(intention)}
                          </span>
                          <span className="u-ml-5">
                            {intention.intentionText}
                          </span>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </Panel>
      </Collapse>
    ) : eventIntentions.length > 0 && eventIntentions.length <= 5 ? (
      <div className="event-data__section">
        <List
          header={
            <Text strong>
              {gettextCatalog.getPlural(
                size(eventIntentions),
                '1 assigned intention',
                '{{ $count }} assigned intentions',
                {
                  $count: size(eventIntentions),
                }
              )}
            </Text>
          }
          itemLayout="horizontal"
          dataSource={eventIntentions}
          renderItem={(intention) => (
            <List.Item key={intention.id}>
              <List.Item.Meta
                title={
                  <div className="media-left">
                    <div className="u-flex u-flex-columns u-align-items-center">
                      <span
                        className="u-ml-10"
                        style={{ minWidth: 'fit-content' }}
                      >
                        {intention.formattedReferenceNumber}
                      </span>
                      <span className="u-text-cap u-ml-10">
                        {getIntentionPriority(intention)}
                      </span>
                      <span className="u-ml-10 u-mr-10 u-max-lines-1">
                        {intention.intentionText}
                      </span>
                    </div>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </div>
    ) : !eventIntentions.length ? (
      <div className="event-data__section">
        <Text strong>{gettextCatalog.getString('Intentions')}</Text>
      </div>
    ) : null;
  };

const IntentionsAndStoleSection: FunctionComponent<{
  eventIntentions: Intention[];
  eventStole: Stole;
  detailedEvent: DetailedEvent;
  closeToolTip: (e?: any) => void;
  canAddIntentions: boolean;
  canAddStoles: boolean;
}> = ({
  eventIntentions,
  eventStole,
  detailedEvent,
  closeToolTip,
  canAddIntentions,
  canAddStoles,
}) => {
  const dispatch = useDispatch();

  const createIntention = useCallback(() => {
    if (!detailedEvent) return null;
    navigate('app.private.intention.create', {
      currentState: 'create',
      calendarId: detailedEvent.id,
    });
  }, [detailedEvent]);

  const createStole = useCallback(() => {
    if (!detailedEvent) return null;
    dispatch(
      showModal(MODAL_TYPES.CREATE_STOLE, {
        event: detailedEvent,
        closeToolTip,
      })
    );
  }, [detailedEvent, dispatch, closeToolTip]);

  const editStole = useCallback(() => {
    if (!detailedEvent) return null;
    dispatch(
      showModal(MODAL_TYPES.CREATE_STOLE, {
        event: detailedEvent,
        stole: eventStole,
        closeToolTip,
      })
    );
  }, [detailedEvent, eventStole, closeToolTip, dispatch]);

  const deleteStole = useCallback(() => {
    showConfirmModal({
      title: gettextCatalog.getString('Delete stole?'),
      message: gettextCatalog.getString(
        'Do you want to delete the stole {{referenceNumber}}?',
        {
          referenceNumber: eventStole.formattedReferenceNumber,
        }
      ),
      okText: gettextCatalog.getString('Delete'),
      okButtonProps: { danger: true },
      onOk: () => {
        StoleService.deleteStole(eventStole.id)
          .then(() => {
            handleSuccessMessage(
              gettextCatalog.getString('Successfully deleted stole.')
            );
            closeToolTip();
            dispatch(fullCalendarActions.reloadCurrentView());
          })
          .catch(ErrorHandlingService.handleError);
      },
    });
  }, [eventStole, dispatch, closeToolTip]);

  return (
    <>
      {canAddIntentions && (
        <>
          <IntentionsSection eventIntentions={eventIntentions} />
          <div className="event-data__section u-pl-25i u-pt-0i">
            <a onClick={createIntention}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
              <span>{gettextCatalog.getString('Create Intention')}</span>
            </a>
          </div>
        </>
      )}

      {canAddStoles ? (
        <>
          <div className="event-data__section">
            <div className="u-flex u-flex-grow u-justify-content-between u-align-items-center">
              <div className="event-data__label">
                {gettextCatalog.getString('Stole')}
              </div>
            </div>
          </div>
          <CreateStoleModal />
          {!isNil(eventStole) ? (
            <div className="u-pl-15 u-pb-5">
              <div className="u-flex u-flex-columns u-align-items-center">
                <span className="u-ml-10" style={{ minWidth: 'fit-content' }}>
                  {eventStole.formattedReferenceNumber}
                </span>
                <span className="u-ml-10 u-max-lines-2">
                  {eventStole.stoleText}
                </span>
                <CdTooltip title={gettextCatalog.getString('Edit Stole')}>
                  <a className="u-ml-10" onClick={editStole}>
                    <CdEditIcon size="sm" />
                  </a>
                </CdTooltip>
                <CdTooltip title={gettextCatalog.getString('Delete stole')}>
                  <a className="u-ml-10" onClick={deleteStole}>
                    <CdDeleteIcon size="sm" />
                  </a>
                </CdTooltip>
              </div>
            </div>
          ) : (
            <div className="event-data__section u-pl-25i u-pt-0i">
              <a onClick={createStole}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
                <span>{gettextCatalog.getString('Create Stole')}</span>
              </a>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export const EventPopoverContent: FunctionComponent<{
  stopPropagation: (e: any) => void;
  eventId: number;
  eventType: EventType;
  closeToolTip: (e?: any) => void;
}> = (props) => {
  const { eventId, eventType, closeToolTip } = props;
  const { sendAnswerToEvent } = useAnswerToEvent(eventId);
  const showChurchSelector: boolean = useRecoilValue(HasMultipleChurches);
  // eslint-disable-next-line destructuring/no-rename
  const { data: detailedEvent } = useGetEvent(eventId, 3, eventType);
  const isExternalEvent = detailedEvent?.type === EventType.External;
  const toggleIsMinimizedView = useDispatchToggleIsMinimizedView();

  const mainCategory =
    detailedEvent?.mainCategory &&
    (get(detailedEvent, [
      'taxonomies',
      detailedEvent.mainCategory,
    ]) as Taxonomy);
  let headerColor =
    isFinite(mainCategory?.color) && mainCategory?.color !== -1
      ? COLOR_INDEX[mainCategory?.color]
      : NO_COLOR_LOADING;
  if (EventType.Feed === detailedEvent?.type) {
    headerColor =
      COLOR_INDEX[detailedEvent?.iCalSubscription?.color] || NO_COLOR;
  }
  if (EventType.External === detailedEvent?.type) {
    headerColor = NO_COLOR;
  }

  const getLocationString = useCallback(() => {
    if (detailedEvent) {
      if (detailedEvent.locationObj) {
        return appUtils.formatGoogleLocation(detailedEvent.locationObj);
      }
      return detailedEvent.location;
    }
    return '';
  }, [detailedEvent]);

  const otherCategories = useMemo(() => {
    if (!detailedEvent) return [];
    return chain(detailedEvent.taxonomies as Taxonomies)
      .values()
      .filter((item) => !item.isMaster)
      .orderBy('name')
      .value();
  }, [detailedEvent]);

  const resources = useMemo(() => {
    if (!detailedEvent) return [];
    return chain(detailedEvent.resources as unknown as Taxonomies)
      .values()
      .orderBy('name')
      .value();
  }, [detailedEvent]);

  // Intention and stole
  const hasIntentionAccess = AuthorizationService.hasPackage('intentions');
  const intentionFees = useSelector(getIntentionFees);
  const intentionFeeTaxonomyIds = useMemo(() => {
    const intentionFeeTaxonomyIds: number[] = [];
    each(intentionFees, (intentionFee) => {
      const intentionFeeTaxonomies = get(intentionFee, 'taxonomies');
      if (!isEmpty(intentionFeeTaxonomies)) {
        intentionFeeTaxonomyIds.push(...map(intentionFeeTaxonomies, 'id'));
      }
    });
    return intentionFeeTaxonomyIds;
  }, [intentionFees]);

  const usersIndexedLoadable = useRecoilValueLoadable(OrganizationUsersIndexed);
  const usersIndexed = useMemo<{ [userId: number]: SlimUser }>(
    () =>
      usersIndexedLoadable.state === 'hasValue'
        ? usersIndexedLoadable.contents
        : [],
    [usersIndexedLoadable.contents, usersIndexedLoadable.state]
  );

  const stoleFeeTaxonomyIds = useSelector(getStoleFeeTaxonomyIds);
  // eslint-disable-next-line destructuring/no-rename
  const { data: eventIntentions } = useFetchEventIntentions(eventId);
  // eslint-disable-next-line destructuring/no-rename
  const { data: eventStole } = useFetchEventStole(eventId);

  const canAddIntentions = useMemo(() => {
    if (!detailedEvent) return false;
    const eventTaxonomyIds = map(keys(detailedEvent.taxonomies), (id) =>
      parseInt(id, 10)
    );
    const sharesEventCommonTaxonomiesWithIntentionFees = !isEmpty(
      intersection(eventTaxonomyIds, intentionFeeTaxonomyIds)
    );
    return hasIntentionAccess && sharesEventCommonTaxonomiesWithIntentionFees;
  }, [detailedEvent, intentionFeeTaxonomyIds, hasIntentionAccess]);

  const canAddStoles = useMemo(() => {
    if (!detailedEvent) return false;
    const eventTaxonomyIds = map(keys(detailedEvent.taxonomies), (id) =>
      parseInt(id, 10)
    );
    const sharesEventCommonTaxonomiesWithStoleFees = !isEmpty(
      intersection(eventTaxonomyIds, stoleFeeTaxonomyIds)
    );
    return hasIntentionAccess && sharesEventCommonTaxonomiesWithStoleFees;
  }, [detailedEvent, stoleFeeTaxonomyIds, hasIntentionAccess]);

  const navigateToUser = useCallback(
    (userId: number) => {
      if (!detailedEvent) return null;
      navigate('app.private.settings.users.detail', { id: userId });
    },
    [detailedEvent]
  );

  const getUserUrl = (userId: number) => {
    if (!detailedEvent) return null;
    return getStateUrl('app.private.settings.users.detail', { id: userId });
  };

  const deleteEventDispatcher = fullCalendarHooks.useDeleteEvent();
  const editEventDispatch = fullCalendarHooks.useEditEvent();
  const startEditEvent = useCallback(() => {
    editEventDispatch({ eventId: eventId, type: 'absence' });
  }, [editEventDispatch, eventId]);
  const deleteEvent = useCallback(() => {
    deleteEventDispatcher(eventId);
  }, [deleteEventDispatcher, eventId]);

  const churches = useMemo(() => {
    if (detailedEvent) {
      return detailedEvent.churches ? detailedEvent.churches : [];
    }
    return [];
  }, [detailedEvent]);

  const users = orderUsers<User>(
    map(detailedEvent?.users, (user, index) => ({ id: index, ...user }))
  );

  const userAttendanceSummary = useMemo(() => {
    if (!detailedEvent) return false;

    const usersFiltered = filter(
      detailedEvent.users,
      (user, userId) => usersIndexed && !!usersIndexed[userId]
    );
    const numbers = countBy(usersFiltered, 'attending');

    const strings = mapValues(numbers, (value, key) => {
      switch (key) {
        case 'yes':
          return {
            order: 1,
            text: gettextCatalog.getString('{{ n }} going', { n: value }),
          };
        case 'maybe':
          return {
            order: 2,
            text: gettextCatalog.getString('{{ n }} maybe', { n: value }),
          };
        case 'no':
          return {
            order: 3,
            text: gettextCatalog.getString('{{ n }} not going', {
              n: value,
            }),
          };
        case 'no-answer':
          return {
            order: 4,
            text: gettextCatalog.getString('{{ n }} awaiting', {
              n: value,
            }),
          };
      }
    });

    return chain(strings)
      .values()
      .orderBy('order')
      .map('text')
      .join(', ')
      .value();
  }, [detailedEvent, usersIndexed]);

  const currentUserId = useSelector(getCurrentUserId);

  const currentUserBooking =
    detailedEvent?.users && detailedEvent.users[currentUserId];

  const changeAttendingStatus = eventsDetailsHooks.useChangeAttendingStatus();

  const copyEventDispatch = fullCalendarHooks.useCopyEvent();
  const startCopyEvent = useCallback(() => {
    copyEventDispatch(eventId);
  }, [copyEventDispatch, eventId]);

  const preparationStartDate = detailedEvent?.preparationStartDate;
  const cleanupEndDate = detailedEvent?.cleanupEndDate;
  const originalStartDate = detailedEvent?.startDate;
  const originalEndDate = detailedEvent?.endDate;
  const prepTimeMinutes = moment(originalStartDate).diff(
    preparationStartDate,
    'minutes'
  );
  const cleanupTimeMinutes = moment(cleanupEndDate).diff(
    originalEndDate,
    'minutes'
  );
  const getCleanupTimeLabel = useCallback(
    (minutes: number, isPreptime: boolean) => {
      // Show hour
      if (minutes >= 60) {
        const numHours = getCommaFormattedNumber(minutes / 60, 1);
        return gettextCatalog.getPlural(
          minutes / 60,
          isPreptime ? '1h. preparation' : '1h. clean up',
          isPreptime ? '{{numHours}}h. preparation' : '{{numHours}}h. clean up',
          {
            numHours,
          }
        );
      } else {
        // Show minutes
        return gettextCatalog.getString(
          isPreptime
            ? '{{amount}} min. preparation'
            : '{{amount}} min. clean up',
          {
            amount: minutes,
          }
        );
      }
    },
    []
  );

  const isPublicEvent =
    detailedEvent?.type === 'event' &&
    detailedEvent?.visibility === Visibility.Public;
  const hasSignupForm = useMemo(() => {
    if (!detailedEvent) return false;
    return !!detailedEvent.form;
  }, [detailedEvent]);

  const { canEdit, canCreate } = detailedEvent || {};
  const eventRefresher = useRecoilRefresher(
    EventQuery({ calendarId: eventId })
  );

  const eventIcon = getIcon(detailedEvent);
  const title = (
    <div>
      <PopoverActionButtons>
        {detailedEvent?.type === 'absence' && (
          <CdTooltip title={gettextCatalog.getString('Group all absences')}>
            <a
              className="event-popup__button-expand text-md"
              onClick={toggleIsMinimizedView}
            >
              <CdGroupAbsences size="sm" style={{ margin: 5 }} />
              {gettextCatalog.getString('Group absences')}
            </a>
          </CdTooltip>
        )}
        {detailedEvent &&
          !['feed', 'external', 'externalEvent'].includes(
            detailedEvent.type
          ) && (
            <CdTooltip title={gettextCatalog.getString('View')}>
              <Button
                shape="circle"
                type="text"
                className="event-popup__action-button"
                href={getStateUrl(
                  `app.private.calendar.${detailedEvent.type}`,
                  {
                    id: eventId,
                  }
                )}
                onClick={(e) => {
                  closeToolTip(e);
                  return navigate(
                    `app.private.calendar.${detailedEvent.type}`,
                    {
                      id: eventId,
                    }
                  );
                }}
              >
                <CdEyeIcon size="sm" />
              </Button>
            </CdTooltip>
          )}
        {canEdit ? (
          <CdTooltip title={gettextCatalog.getString('Edit')}>
            <Button
              shape="circle"
              type="text"
              className="event-popup__action-button"
              href={getStateUrl(`app.private.calendar.${detailedEvent.type}`, {
                id: eventId,
              })}
              onClick={(e) => {
                closeToolTip(e);
                if (detailedEvent.type === 'absence') {
                  startEditEvent();
                } else {
                  navigate(`app.private.calendar.${detailedEvent.type}`, {
                    id: eventId,
                  });
                }
              }}
            >
              <CdEditIcon size="sm" />
            </Button>
          </CdTooltip>
        ) : null}
        {canEdit && canCreate ? (
          <CdTooltip title={gettextCatalog.getString('Copy')}>
            <Button
              shape="circle"
              type="text"
              className="event-popup__action-button"
              onClick={(e) => {
                closeToolTip(e);
                if (detailedEvent.type === 'absence') {
                  startCopyEvent();
                } else {
                  openEventDrawer({ copyEventId: detailedEvent.id });
                }
              }}
            >
              <CdCopyIcon size="sm" />
            </Button>
          </CdTooltip>
        ) : null}

        <CdTooltip title={gettextCatalog.getString('Print')}>
          <Button
            shape="circle"
            type="text"
            className="event-popup__action-button"
            onClick={(e) => {
              closeToolTip(e);
              showPrintModal({
                eventId,
                accessToSensitiveInfo:
                  detailedEvent?.fields.secureInformation.canEdit,
              });
            }}
          >
            <CdPrint size="sm" />
          </Button>
        </CdTooltip>

        {detailedEvent && detailedEvent.canDelete ? (
          <CdTooltip title={gettextCatalog.getString('Delete')}>
            <Button
              shape="circle"
              type="text"
              className="event-popup__action-button"
              onClick={deleteEvent}
            >
              <CdDeleteIcon size="sm" />
            </Button>
          </CdTooltip>
        ) : null}
        <CdTooltip title={gettextCatalog.getString('Close')}>
          <Button
            shape="circle"
            type="text"
            className="event-popup__action-button u-ml-10"
            onClick={closeToolTip}
          >
            <CdClose size="sm" />
          </Button>
        </CdTooltip>
      </PopoverActionButtons>
      <PopoverTitle>
        {eventIcon ? (
          <>
            <FontAwesomeIcon icon={eventIcon} />
            {'\u00A0'}
          </>
        ) : null}
        {detailedEvent ? (
          detailedEvent?.title
        ) : (
          <Skeleton loading paragraph={false} active title></Skeleton>
        )}
      </PopoverTitle>
      {!mainCategory ? null : (
        <div style={{ fontSize: 14 }}>
          <div
            style={{ flex: 1, overflowX: 'hidden' }}
            title={mainCategory.name}
          >
            {mainCategory.name}
          </div>
          <ChurchesSection
            churches={churches}
            showChurchSelector={showChurchSelector}
          />
        </div>
      )}
    </div>
  );
  return (
    <PopoverContentCard
      title={title}
      bordered={false}
      $isPublic={isPublicEvent}
      onClick={(e) => {
        e.stopPropagation();
      }}
      headStyle={{
        backgroundColor: headerColor,
      }}
      actions={
        detailedEvent && detailedEvent.type === 'event' && currentUserBooking
          ? [
              <div className="event-popup__footer">
                <div className="animated fadeIn">
                  {gettextCatalog.getString('Your answer')}
                </div>

                <div className="animated fadeIn">
                  <button
                    type="button"
                    className={`btn btn-xs${
                      currentUserBooking.attending === 'yes'
                        ? ' btn-primary'
                        : ' btn-link u-text-muted'
                    }`}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await sendAnswerToEvent('yes');
                      changeAttendingStatus({
                        eventId,
                        eventType,
                        status: 'yes',
                      });
                      eventRefresher();
                    }}
                  >
                    {gettextCatalog.getString('Going')}
                  </button>
                  <button
                    type="button"
                    className={`btn btn-xs${
                      currentUserBooking.attending === 'maybe'
                        ? ' btn-warning'
                        : ' btn-link u-text-muted'
                    }`}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await sendAnswerToEvent('maybe');
                      changeAttendingStatus({
                        eventId,
                        eventType,
                        status: 'maybe',
                      });
                      eventRefresher();
                    }}
                  >
                    {gettextCatalog.getString('Maybe')}
                  </button>
                  <button
                    type="button"
                    className={`btn btn-xs${
                      currentUserBooking.attending === 'no'
                        ? ' btn-danger'
                        : ' btn-link u-text-muted'
                    }`}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await sendAnswerToEvent('no');
                      changeAttendingStatus({
                        eventId,
                        eventType,
                        status: 'no',
                      });
                      eventRefresher();
                    }}
                  >
                    {gettextCatalog.getString('Not going')}
                  </button>
                </div>
              </div>,
            ]
          : null
      }
    >
      {!detailedEvent ? (
        <div className="event-data__section">
          <Skeleton loading active paragraph title></Skeleton>
        </div>
      ) : (
        <div
          style={{ width: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isPublicEvent &&
          !hasSignupForm &&
          detailedEvent.canEdit &&
          detailedEvent.clientVersion !== 2 ? (
            <div className="alert alert-success ng-scope u-align-items-center u-border-radius-ni u-flex u-m-0i">
              <Row
                wrap={false}
                justify="space-around"
                align="middle"
                style={{ width: '100%' }}
              >
                <Col flex="2 1">
                  <Space direction="vertical" size={0}>
                    <b>{gettextCatalog.getString('Add sign-up')}</b>
                    <span className="u-text-muted">
                      {gettextCatalog.getString(
                        'Make it easy for people to sign up from your website'
                      )}
                    </span>
                  </Space>
                </Col>
                <Col flex="1 1" style={{ textAlign: 'right' }}>
                  <Button
                    size="small"
                    onClick={(e) => {
                      closeToolTip(e);
                      navigate('app.private.calendar.event', {
                        id: detailedEvent.id,
                        openAddForm: true,
                      });
                    }}
                  >
                    {gettextCatalog.getString('Add sign-up')}
                  </Button>
                </Col>
              </Row>
            </div>
          ) : null}
          <div className="event-data__section">
            <DisplayDate
              start={detailedEvent.startDate}
              end={detailedEvent.endDate}
              allDay={detailedEvent.allDay}
            />
            {detailedEvent.rrule ? (
              <div className="u-text-muted">
                <CdRRuleSummary
                  rrule={detailedEvent.rrule}
                  startDate={detailedEvent.startDate}
                />
              </div>
            ) : null}
            {/* Preparation time */}
            {!isExternalEvent && prepTimeMinutes > 0 ? (
              <div className="u-text-italic u-text-muted">
                + <span>{getCleanupTimeLabel(prepTimeMinutes, true)}</span>
              </div>
            ) : null}
            {/* Cleanup time */}
            {!isExternalEvent && cleanupTimeMinutes > 0 ? (
              <div className="u-text-italic u-text-muted">
                + <span>{getCleanupTimeLabel(cleanupTimeMinutes, false)}</span>
              </div>
            ) : null}
          </div>

          {detailedEvent.location || detailedEvent.locationName ? (
            <div className="event-data__section">
              <div className="event-data__label">
                <span>{gettextCatalog.getString('Location')}</span>
              </div>
              {detailedEvent.location && !detailedEvent.locationName
                ? getLocationString()
                : detailedEvent.locationName}
            </div>
          ) : null}

          {detailedEvent.contributor ? (
            <div className="event-data__section">
              <div className="event-data__label">
                {gettextCatalog.getString('Contributor')}
              </div>
              {detailedEvent.contributor}
            </div>
          ) : null}

          <CategoriesSection categories={otherCategories} />
          <ResourcesSection
            resources={resources}
            showChurchSelector={showChurchSelector}
          />

          {detailedEvent.type !== 'absence' ? (
            <UsersSection
              users={users}
              usersIndexed={usersIndexed}
              userAttendanceSummary={
                userAttendanceSummary ? userAttendanceSummary : ''
              }
            />
          ) : (
            <AbsenceUser users={users} navigateToUser={navigateToUser} />
          )}

          <RotasSection event={detailedEvent} usersIndexed={usersIndexed} />

          <FilesSection event={detailedEvent} />

          {hasIntentionAccess && detailedEvent.type === 'event' ? (
            <IntentionsAndStoleSection
              eventIntentions={eventIntentions}
              eventStole={eventStole}
              canAddIntentions={canAddIntentions}
              canAddStoles={canAddStoles}
              closeToolTip={closeToolTip}
              detailedEvent={detailedEvent}
            />
          ) : null}

          {detailedEvent && detailedEvent.internalNote ? (
            <div className="event-data__section">
              <div className="event-data__label">
                {gettextCatalog.getString('Internal note')}
              </div>
              <Paragraph
                className="pre-line"
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: gettextCatalog.getString('Read more'),
                }}
              >
                {unEscape(detailedEvent.internalNote)}
              </Paragraph>
            </div>
          ) : null}

          {detailedEvent && detailedEvent.substitute ? (
            <div className="event-data__section">
              <div className="event-data__label">
                {' '}
                {gettextCatalog.getString('Substitute')}{' '}
              </div>
              {detailedEvent.substitute}
            </div>
          ) : null}

          {detailedEvent && detailedEvent.absenceComment ? (
            <div className="event-data__section">
              <div className="event-data__label">
                {gettextCatalog.getString('Internal note')}
              </div>
              <Paragraph
                className="pre-line"
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: gettextCatalog.getString('Read more'),
                }}
              >
                {detailedEvent.absenceComment}
              </Paragraph>
            </div>
          ) : null}

          {detailedEvent && detailedEvent.author ? (
            <div className="event-data__section">
              <div className="event-data__label">
                {gettextCatalog.getString('Created by')}
              </div>
              <a href={getUserUrl(detailedEvent.author.id)}>
                {getUserName(detailedEvent.author)}
              </a>
            </div>
          ) : null}
        </div>
      )}
    </PopoverContentCard>
  );
};

const ListItemBookedUser = ({ user, usersIndexed }) => {
  const navigateToUser = (userId: number) =>
    navigate('app.private.settings.users.detail', { id: userId });

  const slimUser = usersIndexed[user.id];
  const userImage = user.picture || user.image;

  return (
    <List.Item key={user.id}>
      <List.Item.Meta
        avatar={
          userImage ? (
            <Avatar size={25} src={userImage} style={{ marginRight: 8 }} />
          ) : (
            <Avatar
              size={25}
              icon={<FontAwesomeIcon icon={faUser} />}
              style={{ marginRight: 8 }}
            />
          )
        }
        title={
          <>
            <InvitationResponse status={user.attending} />
            <a
              onClick={() => {
                navigateToUser(user.id);
              }}
              className="event-data__user-link"
            >
              <Space>
                <span
                  className={
                    (slimUser?.status === 'blocked' && 'text-danger') ||
                    undefined
                  }
                >
                  {slimUser.name}
                </span>
                {slimUser?.status === 'blocked' && (
                  <small className="text-danger">
                    ({gettextCatalog.getString('Blocked')})
                  </small>
                )}
              </Space>
            </a>
          </>
        }
      />
    </List.Item>
  );
};
