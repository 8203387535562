import {
  InputNumber,
  Form,
  Row,
  Col,
  Select,
  Alert,
  Button,
  Space,
} from 'antd';
import React from 'react';
import { $injector } from 'ngimport';
import { useRecoilValue } from 'recoil';
import { useRecoilRefresher_UNSTABLE as useRecoilRefresher } from 'recoil';
import moment from 'moment';

import {
  MINIMUM_COLUMN_WIDTH,
  MINIMUM_SECOND_COLUMN_WIDTH,
} from '../constants';

import { FieldSection } from './FieldSection';

import { gettextCatalog } from '@/react/services/I18nService';
import { EventIcons, CdOpenInNewWindow } from '@/react/shared/components/Icons';
import { Attendance } from '@/react/calendar/models/calendar';
import { CategoryByTypeQuery } from '@/react/shared/services/CategoryService';
import { CategoryType } from '@/react/shared/models/category';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import {
  OrganizationUsers,
  SelectedOrganizationId,
} from '@/react/organization/store/organization';
import { UibModal } from '@/react/calendar/services/EventPopupService';
import { GetAttendanceFields } from '@/react/calendar/store/events/eventAttendanceFields';
import CdrEmptyState from '@/react/shared/components/CdrEmptyState';

const { Option } = Select;

const CdAttendance = (props: {
  startDate: string | Date;
  value?: Attendance;
  disabled?: boolean;
}) => {
  const eventTitle = Form.useWatch<string>('title');
  const mainCategory = Form.useWatch<number>('mainCategory');

  const organizationId = useRecoilValue(SelectedOrganizationId);
  const { attendanceFields } = props.value;
  const users = useRecoilValue(OrganizationUsers);
  const categories = useRecoilValue(CategoryByTypeQuery(CategoryType.event));
  const refreshAttendanceFields = useRecoilRefresher(GetAttendanceFields);
  const isFutureEvent = moment(props.startDate).isAfter();
  const isAboutToStart =
    moment(props.startDate).diff(moment(), 'minutes') <= 30;
  const eventStartMessage = isFutureEvent && !isAboutToStart;
  const attendanceAllow = categories.find(
    (category) => category.id === mainCategory
  )?.registerAttendance;
  const openAttendanceGetStartedModal = () => {
    const $uibModal = $injector.get('$uibModal') as UibModal;
    $uibModal
      .open({
        windowClass: 'modal-ui-select',
        component: 'cdAttendanceGetStartedModal',
        resolve: {},
      })
      .result.then(() => {
        refreshAttendanceFields();
      });
  };

  return attendanceFields?.length > 0 ? (
    <Row gutter={16}>
      <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
        {!attendanceAllow && (
          <FieldSection>
            <Alert
              style={{ marginBottom: '8px' }}
              type="info"
              message={
                mainCategory ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: gettextCatalog.getString(
                        'The selected category <i>{{category}}</i> has not been enabled for tracking attendance. Go to <link>settings</link> for event categories to enable.',
                        {
                          category: categories.find(
                            (category) => category.id === mainCategory
                          )?.name,
                          link: (text: string) =>
                            `<a href="/o/${organizationId}/settings/calender/event" target="_blank">${text}</a>`,
                        }
                      ),
                    }}
                  ></span>
                ) : (
                  gettextCatalog.getString(
                    "You haven't selected a main category yet."
                  )
                )
              }
            />
          </FieldSection>
        )}
        <Form.Item
          label={
            <span
              dangerouslySetInnerHTML={{
                __html: gettextCatalog.getString(
                  'Register attendance for <i>{{title}}</i> at <i>{{startDate}}</i>',
                  {
                    title:
                      eventTitle || gettextCatalog.getString('Unnamed event'),
                    startDate: moment(props.startDate).format('lll'),
                  }
                ),
              }}
            ></span>
          }
          style={{ marginBottom: 0 }}
        >
          <FieldSection>
            {eventStartMessage ? (
              <Row gutter={16} style={{ marginBottom: '8px' }}>
                <Alert
                  style={{ margin: '8px' }}
                  message={gettextCatalog.getString(
                    "The event hasn't started yet!"
                  )}
                  description={gettextCatalog.getString(
                    'You will be able to start registering attendance 30 minutes before the event starts.'
                  )}
                  type="info"
                  showIcon
                />
              </Row>
            ) : undefined}
          </FieldSection>
          {attendanceFields.map((attendanceField) => (
            <FieldSection key={attendanceField.id}>
              <Form.Item
                label={attendanceField.name}
                name={['attendance', attendanceField.name]}
                rules={[
                  { type: 'integer' },
                  () => ({
                    validator(rule, value) {
                      if (
                        (value && value > 0) ||
                        value === 0 ||
                        value === null ||
                        value === undefined
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          gettextCatalog.getString(
                            'Negative values are not allowed.'
                          )
                        );
                      }
                    },
                  }),
                ]}
                initialValue={attendanceField.amount}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  disabled={
                    !attendanceAllow || eventStartMessage || props.disabled
                  }
                />
              </Form.Item>
            </FieldSection>
          ))}
          <FieldSection>
            <Form.Item
              label={gettextCatalog.getString('Vicar/leader')}
              name={['attendance', 'vicar', 'id']}
            >
              <Select
                optionFilterProp="children"
                showSearch
                disabled={
                  !attendanceAllow || eventStartMessage || props.disabled
                }
                placeholder={gettextCatalog.getString('Select a user')}
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </FieldSection>
        </Form.Item>
      </Col>
      <Col flex={`${MINIMUM_SECOND_COLUMN_WIDTH + 16}px`}>
        <Row
          style={
            eventStartMessage
              ? { marginLeft: '24px', marginTop: '42px' }
              : { marginLeft: '24px' }
          }
        >
          <FieldSection>
            <Button
              target="_blank"
              href={getStateUrl('app.private.calendar.attendance', {
                tab: 'current',
              })}
            >
              <Space>
                {gettextCatalog.getString('See the report for all attendance')}
                <CdOpenInNewWindow />
              </Space>
            </Button>
          </FieldSection>
        </Row>
      </Col>
    </Row>
  ) : (
    <Row>
      <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
        <CdrEmptyState
          title={gettextCatalog.getString('Track your attendance')}
          subtitle={gettextCatalog.getString(
            'Attendance statistics can provide you with valuable insights and help you be more strategic, focusing on the mission and ministry, instead of trying to work in areas that are not fruitful.'
          )}
          buttonText={gettextCatalog.getString('Get started')}
          EmptyStateIcon={<EventIcons.Attendance />}
          onButtonClick={openAttendanceGetStartedModal}
          buttonDisabled={props.disabled}
        />
      </Col>
    </Row>
  );
};

export default CdAttendance;
