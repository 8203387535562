import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Tabs,
  Typography,
} from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { CalendarEventTabs } from '../types/event';
import { required } from '../../shared/antd/validations/common';
import { CdVerticalSpace } from '../../shared/components/cd-vertical-space/CdVerticalSpace';

import { EventAccessContext, NewEventProps, useEvent } from './hooks/use-event';
import { EventControls } from './components/form-items/EventControls';
import { EventDetails } from './tabs/event-details/EventDetails';
import { FieldSection } from './components/FieldSection';
import { MINIMUM_COLUMN_WIDTH } from './constants';
import { FormItemWithAccess } from './components/form-items/FormItemWithAccess';
import CdAttendance from './components/CdAttendance';
import Comments from './components/comments/Comments';
import { CommentTabHeader } from './components/comments/CommentTabHeader';
import { UserAnswerToEvent } from './components/form-items/UserAnswerToEvent';
import { VersionHistory } from './components/VersionHistory.table';
import { SignUpFormTab } from './components/SignUpFormTab';
import { PosterTab } from './tabs/poster/PosterTab';
import { InviteTab } from './tabs/invite/InviteTab';
import { UponCloseInterface } from './hooks/use-close-modal';
import { RotasAndIntentionsTab } from './tabs/RotasAndIntentionsTab';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import { CdUserAvatarWithState } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import AuthorizationService from '@/react/services/AuthorizationService';
import { CdNotFound } from '@/react/shared/components/cd-not-found/CdNotFound';
import { DateInput } from '@/react/calendar/event-details/components/date-input/DateInput';
import { CdVersionHistory } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

export const EventDetailsPage = (props: {
  eventId?: number;
  newEvent?: NewEventProps;
  close: (input?: UponCloseInterface) => Promise<void>;
  form: FormInstance;
  startingTab?: CalendarEventTabs;
}) => {
  const [currentTab, setCurrentTab] = useState<CalendarEventTabs>(
    props.startingTab || CalendarEventTabs.DETAILS_PAGE
  );
  const [revisionVersion, setRevisionVersion] = useState<{
    version: string;
    authorId?: number;
  }>({ version: 'current', authorId: null });
  const {
    event,
    eventAccessContext,
    churchSettings,
    onSave,
    onDelete,
    saveProcessing,
    saveAndClose,
    eventInitialFormData,
    showConflicts,
    setEventFormWithDataBasedOnVersionHistory,
  } = useEvent({
    eventId: props.eventId,
    newEvent: props.newEvent,
    form: props.form,
    close: props.close,
    setCurrentTab,
    setRevisionVersion,
  });

  const isNew = !props.eventId;
  const hasIntentionPackage = AuthorizationService.hasPackage('intentions');
  const hasFormsPackage = AuthorizationService.hasPackage('forms');
  const isViewingPreviousEvent = revisionVersion.version !== 'current';

  const date = Form.useWatch('date', props.form);
  const visibilitySetting = Form.useWatch('visibilitySetting', props.form);

  if (!event && !isNew) {
    return (
      <CdNotFound
        title={gettextCatalog.getString('Not found')}
        subTitle={
          <Space direction="vertical">
            {gettextCatalog.getString('The event could not be found')}
            <Button onClick={() => props.close()}>
              {gettextCatalog.getString('Close window')}
            </Button>
          </Space>
        }
      />
    );
  }

  return (
    <EventAccessContext.Provider
      value={{
        eventAccess: eventAccessContext,
        disabledByVersionHistory: isViewingPreviousEvent,
      }}
    >
      <Form
        id="formId"
        form={props.form}
        layout="vertical"
        initialValues={eventInitialFormData}
        style={{ minWidth: '1000px' }}
      >
        <Form.Item hidden noStyle name="type">
          <Input />
        </Form.Item>
        <Form.Item hidden noStyle name="visibility">
          <Input />
        </Form.Item>

        <CdVerticalSpace>
          <Row gutter={16}>
            <Col flex={`${MINIMUM_COLUMN_WIDTH + 16}px`}>
              <CdVerticalSpace size={24}>
                <FieldSection>
                  <FormItemWithAccess
                    name="title"
                    rules={[
                      required(
                        gettextCatalog.getString('An event title is required.')
                      ),
                    ]}
                    noErrorMessage
                  >
                    <Input
                      size="large"
                      placeholder={gettextCatalog.getString('Add title')}
                    />
                  </FormItemWithAccess>
                </FieldSection>

                <FieldSection>
                  <FormItemWithAccess name="date" fieldAccessName="startDate">
                    <DateInput
                      eventId={event?.id}
                      previousRRule={event?.rrule}
                    />
                  </FormItemWithAccess>
                </FieldSection>
              </CdVerticalSpace>
            </Col>
            <Col flex="auto">
              {!isViewingPreviousEvent ? (
                <FieldSection>
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.users !== currentValues?.users ||
                      prevValues?.rotasAndIntentions.shiftsAndIntentions !==
                        currentValues?.rotasAndIntentions.shiftsAndIntentions ||
                      prevValues?.visibilitySetting !==
                        currentValues?.visibilitySetting
                    }
                    style={{ marginBottom: 0 }}
                  >
                    {({ getFieldValue }) => {
                      const users = getFieldValue('users');
                      const visibilitySetting =
                        getFieldValue('visibilitySetting');
                      const shiftsAndIntentions =
                        getFieldValue('rotasAndIntentions').shiftsAndIntentions;
                      const hasAnyRotasUser = shiftsAndIntentions?.some(
                        (item) => {
                          const shifts = item.shifts;
                          return shifts.some(
                            (shift) => shift.users?.length > 0
                          );
                        }
                      );

                      return (
                        <EventControls
                          {...{
                            onSave,
                            onDelete,
                            saveAndClose,
                            saveProcessing,
                            close: props.close,
                            eventId: event?.id,
                            areAnyUsersBooked:
                              users?.length > 0 || hasAnyRotasUser,
                            isPublicEvent:
                              visibilitySetting?.visibility === 'public',
                            eventUrl: event?.url,
                          }}
                        />
                      );
                    }}
                  </Form.Item>

                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues?.users !== currentValues?.users
                    }
                    style={{
                      marginTop: '24px',
                    }}
                  >
                    {({ getFieldValue }) => {
                      const users = getFieldValue('users');

                      return (
                        <UserAnswerToEvent
                          bookingUsers={users ? users : []}
                          eventId={event?.id}
                        />
                      );
                    }}
                  </Form.Item>
                </FieldSection>
              ) : (
                <FieldSection>
                  <Alert
                    message={
                      <Space direction="vertical">
                        <Typography.Text strong>
                          <Space>
                            {gettextCatalog.getString('Event version:')}
                            {revisionVersion.version}
                          </Space>
                        </Typography.Text>
                        <Space>
                          {gettextCatalog.getString('By:')}
                          <CdUserAvatarWithState
                            userId={revisionVersion.authorId}
                            size="small"
                          />
                        </Space>
                      </Space>
                    }
                    description={
                      <Button
                        style={{ marginTop: `8px` }}
                        type="primary"
                        onClick={() =>
                          setEventFormWithDataBasedOnVersionHistory({
                            isFromBackToCurrentVesionButton: true,
                          })
                        }
                      >
                        {gettextCatalog.getString('Back to current version')}
                      </Button>
                    }
                    style={{ width: `400px` }}
                    type="info"
                    icon={
                      <CdVersionHistory
                        style={{ marginRight: '16px', marginTop: '4px' }}
                        size="lg"
                      />
                    }
                    showIcon
                  />
                </FieldSection>
              )}
            </Col>
          </Row>

          <CustomTabs
            defaultActiveKey={CalendarEventTabs.DETAILS_PAGE}
            type="card"
            activeKey={currentTab}
            onTabClick={(key: CalendarEventTabs) => setCurrentTab(key)}
            items={[
              {
                label: gettextCatalog.getString('Event details'),
                key: CalendarEventTabs.DETAILS_PAGE,
                forceRender: true,
                children: (
                  <EventDetails
                    churchSettings={churchSettings}
                    event={event}
                    form={props.form}
                    showConflicts={showConflicts}
                  />
                ),
              },
              {
                label: hasIntentionPackage
                  ? gettextCatalog.getString('Rota & Intentions')
                  : gettextCatalog.getString('Rota', undefined, 'Event form'),
                key: CalendarEventTabs.ROTA_AND_INTENTIONS,
                forceRender: true,
                children: (
                  <RotasAndIntentionsTab
                    calendarId={props.eventId}
                    onSave={onSave}
                    saveProcessing={saveProcessing}
                    isViewingPreviousEvent={isViewingPreviousEvent}
                  />
                ),
              },
              hasFormsPackage && {
                label: gettextCatalog.getString('Sign-ups'),
                key: CalendarEventTabs.SIGN_UPS,
                disabled: isViewingPreviousEvent,
                children: (
                  <SignUpFormTab
                    eventId={event?.id}
                    canEdit={
                      (!event || event?.canEdit) &&
                      eventAccessContext.fields.form?.canEdit
                    }
                  />
                ),
              },
              {
                label: (
                  <CdTooltip
                    title={
                      date?.startDate.diff(moment(), 'minutes') > 30 &&
                      gettextCatalog.getString(
                        "The event hasn't started yet. You will be able to start registering attendance 30 minutes before the event is set to begin."
                      )
                    }
                  >
                    {gettextCatalog.getString('Attendance')}
                  </CdTooltip>
                ),
                key: CalendarEventTabs.ATTENDANCE,
                disabled:
                  visibilitySetting?.visibility === 'private' ||
                  date?.startDate.diff(moment(), 'minutes') > 30,
                children: (
                  <FormItemWithAccess name="attendance">
                    <CdAttendance startDate={date?.startDate} />
                  </FormItemWithAccess>
                ),
              },
              {
                label: gettextCatalog.getString('Poster'),
                key: CalendarEventTabs.POSTER,
                disabled: isViewingPreviousEvent || isNew,
                children: (
                  <PosterTab eventId={event?.id} saveAndClose={saveAndClose} />
                ),
              },
              {
                label: gettextCatalog.getString('Invite'),
                key: CalendarEventTabs.INVITE,
                disabled: isViewingPreviousEvent || isNew,
                children: (
                  <InviteTab eventId={event?.id} saveAndClose={saveAndClose} />
                ),
              },
              {
                label: <CommentTabHeader eventId={event?.id} />,
                key: CalendarEventTabs.COMMENTS,
                disabled: isViewingPreviousEvent || isNew,
                children: <Comments eventId={event?.id} />,
              },
              {
                label: gettextCatalog.getString('Version history'),
                key: CalendarEventTabs.VERSION_HISTORY,
                disabled: isNew || !eventAccessContext.canEdit,
                children: (
                  <VersionHistory
                    eventId={event?.id}
                    setEventFormWithDataBasedOnVersionHistory={
                      setEventFormWithDataBasedOnVersionHistory
                    }
                  />
                ),
              },
            ]}
          />
        </CdVerticalSpace>
      </Form>
    </EventAccessContext.Provider>
  );
};

export const CustomTabs = styled(Tabs)`
  &&&& .ant-tabs-tab {
    min-width: 140px;
    justify-content: center;
  }
`;
