import { selectorFamily } from 'recoil';

import { mainApi } from '@/react/api';

export const AuthenticateSSOToken = selectorFamily<
  { accessToken?: string; error?: string; email?: string },
  { idToken: string; state: string; code: string }
>({
  key: 'AuthenticateSSOToken',
  get:
    ({ idToken, state, code }) =>
    async () => {
      if (!idToken && !code) return { error: 'Missing SSO id_token' };
      const response = await mainApi.post<any>(`/login/sso`, {
        idToken,
        state,
        code,
      });

      if (!response.ok) {
        return {
          error: response.data.message,
          email: response.data.error?.email,
        };
      }
      return {
        accessToken: response.data.accessToken,
      };
    },
});
