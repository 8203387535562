import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { MessageSettings } from '@/react/people/components/MesssageSettings';

angular
  .module('cdApp.people')
  .component(
    'cdrMessageSettings',
    react2angular(
      sharedContext.use(MessageSettings),
      [
        'smsNotification',
        'emailNotification',
        'canView',
        'contactId',
        'contactName',
        'updateMessageSettings',
        'communicateFromFilters',
        'unSubscriptions',
        'updateReceiveCommunicationFromFilters',
      ],
      []
    )
  );
