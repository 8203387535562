import { Form, Row, Col, Space, Alert, Spin } from 'antd';
import { isEmptyObject } from 'jquery';
import React, { Suspense } from 'react';

import { useSignUp } from '../hooks/use-sign-up';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { CdSignUp } from './CdSignUp';
import EditEventForm from './EditEventForm';
import { FieldSection } from './FieldSection';
import RemoveFormFromEvent from './RemoveFormFromEvent';
import SignUpPreview from './SignUpPreview';
import { FormItemNoErrorMessage } from './form-items/FormItemNoErrorMessage';
import AttendeesListView from './attendees-list/AttendeesListView';

import { EventForm } from '@/react/calendar/types/event';
import { gettextCatalog } from '@/react/services/I18nService';

export const SignUpFormTab = ({
  eventId,
  canEdit,
}: {
  eventId: number;
  canEdit: boolean;
}) => (
  <Suspense
    fallback={
      <Spin>
        <CdSignUp canEdit={canEdit} />
      </Spin>
    }
  >
    <Form.Item name="form" noStyle>
      <SignUpFormItem eventId={eventId} canEdit={canEdit} />
    </Form.Item>
  </Suspense>
);

function SignUpFormItem({
  eventId,
  canEdit,
  value,
  onChange,
}: {
  eventId: number;
  canEdit: boolean;
  onChange?: (value: EventForm) => void;
  value?: EventForm;
}) {
  const {
    addAttendee,
    editAttendee,
    resendTickets,
    deleteAttendee,
    refreshAttendees,
    attendees,
    formHasBeenChanged,
    hasTickets,
    hasMoreThanOnePeopleSection,
  } = useSignUp(eventId, value);

  return value === undefined || value === null || isEmptyObject(value) ? (
    <CdSignUp canEdit={canEdit} value={value} onChange={onChange} />
  ) : (
    <Row>
      {formHasBeenChanged ? (
        <Col span={24}>
          <FieldSection>
            <Form.Item label={gettextCatalog.getString('Attendees')}>
              <Alert
                type="info"
                message={gettextCatalog.getString(
                  'A new form has been added. Save the event to see the attendees table.'
                )}
              />
            </Form.Item>
          </FieldSection>
        </Col>
      ) : (
        !hasMoreThanOnePeopleSection && (
          <Col span={24}>
            <FieldSection>
              <Form.Item label={gettextCatalog.getString('Attendees')}>
                <AttendeesListView
                  eventId={eventId}
                  hasTickets={hasTickets}
                  attendees={attendees}
                  onAddAttendee={value.link ? addAttendee : undefined}
                  onEditAttendee={editAttendee}
                  onRefreshAttendees={refreshAttendees}
                  onResendTickets={resendTickets}
                  onDeleteAttendee={deleteAttendee}
                />
              </Form.Item>
            </FieldSection>
          </Col>
        )
      )}
      <Col span={24}>
        <FieldSection>
          <FormItemNoErrorMessage
            label={gettextCatalog.getString('Sign-up preview')}
          >
            <CdVerticalSpace>
              <Space>
                <EditEventForm formId={value?.id} canEdit={canEdit} />
                <RemoveFormFromEvent
                  onOk={() => onChange(null)}
                  canEdit={canEdit}
                />
              </Space>
              {value?.id ? (
                <SignUpPreview formLink={value?.link} />
              ) : (
                <Alert
                  type="info"
                  message={gettextCatalog.getString(
                    'Sign-up form has been created. Save the event to see the form preview.'
                  )}
                />
              )}
            </CdVerticalSpace>
          </FormItemNoErrorMessage>
        </FieldSection>
      </Col>
    </Row>
  );
}
