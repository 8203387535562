import en from 'world_countries_lists/data/countries/en/world.json';
import de from 'world_countries_lists/data/countries/de/world.json';
import da from 'world_countries_lists/data/countries/da/world.json';
import fr from 'world_countries_lists/data/countries/fr/countries.json';
import sv from 'world_countries_lists/data/countries/sv/countries.json';

import cdApp from '@/react/config';

export const getCountryList = () => {
  // Never call cdApp outside a function
  const language = cdApp.organization?.locale?.language;
  switch (language) {
    case 'en':
    case 'en-gb':
      return en;
    case 'da':
      return da;
    case 'de':
      return de;
    case 'fr':
      return fr;
    case 'sv':
      return sv;
    default:
      return en;
  }
};

export const getCountryIso2 = (countryName: string) => {
  const normalizedName = countryName?.trim().toLowerCase();

  return getCountryList().find(
    (country) =>
      country.name.toLowerCase() === normalizedName ||
      country.alpha3.toLowerCase() === normalizedName ||
      country.alpha2.toLowerCase() === normalizedName
  )?.alpha2;
};

export const getCountryName = (countryIso2: string) =>
  getCountryList().find((country) => country.alpha2 === countryIso2)?.name;
