import { Progress, Space, Typography } from 'antd';
import React from 'react';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

const { Title } = Typography;
export const StatisticsDiagram = ({
  title,
  tooltipText,
  total,
  actual,
  status = 'normal',
}: {
  title: string;
  tooltipText: string;
  total: number;
  actual: number;
  status?: 'success' | 'normal' | 'exception' | 'active';
}) => {
  const round = (number: number) =>
    Math.round(number) === 0 ? 0 : `${Math.round(number)}%`;
  return (
    <Space direction="vertical" align="center">
      <CdTooltip title={tooltipText}>
        <Title level={3}>{title}</Title>
        <Progress
          type="dashboard"
          percent={(actual / total) * 100}
          format={round}
          status={status}
        />
      </CdTooltip>
    </Space>
  );
};
