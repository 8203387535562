import React, { useCallback } from 'react';
import { Form, Radio, Space, Select } from 'antd';
import { useRecoilValue } from 'recoil';

import { gettextCatalog } from '../../../../services/I18nService';
import { EventIcons } from '../../../../shared/components/Icons';
import { GetAllGroups } from '../../../../organization/store/organization';
import { CdRadioWithIcon } from '../../../../shared/components/cd-radio-with-icon/CdRadioWithIcon';

import { formatOrganizationGroups } from './Visibility.helper';

import AuthorizationService from '@/react/services/AuthorizationService';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';

interface visibilityProps {
  visibility: string;
  groupIds: object[];
}

interface VisibilityWrapperProps {
  isNewEvent: boolean;
  value?: visibilityProps;
  onChange?: any;
  VisibilityAllowedValues: string[];
  disabled?: boolean;
}

export const VisibilityWrapper = (props: VisibilityWrapperProps) => {
  const OrganizationGroups = useRecoilValue(GetAllGroups);
  const formattedGroups = formatOrganizationGroups(
    OrganizationGroups,
    props.VisibilityAllowedValues
  );
  const { status, errors } = Form.Item.useStatus();
  const radioOnChangeHandler = useCallback(
    (e) => {
      const v = e.target.value;
      if (v === 'internal-all' || v === 'private') {
        props.onChange({
          ...props.value,
          visibility: e.target.value,
          groupIds: undefined,
        });
      } else {
        props.onChange({ ...props.value, visibility: e.target.value });
      }
    },
    [props]
  );

  const onGroupChangeHandler = useCallback(
    (groups) => {
      props.onChange({ ...props.value, groupIds: groups });
    },
    [props]
  );

  return (
    <>
      <Form.Item style={{ marginBottom: '0px' }}>
        <Radio.Group
          onChange={radioOnChangeHandler}
          value={props.value?.visibility}
        >
          <Space direction="vertical">
            <CdRadioWithIcon
              Icon={EventIcons.VisibilityPublic}
              iconSize="small"
              value="public"
              title={gettextCatalog.getString(
                'Public: Event can be shared everywhere'
              )}
              label={gettextCatalog.getString(
                'Allow this event on your website and in messages sent from People. Details are shared internally with all users.'
              )}
              align="start"
              disabled={
                !props.VisibilityAllowedValues.includes('public') ||
                props.disabled
              }
            />
            {props.value?.visibility === 'public' && (
              <Form.Item
                label={gettextCatalog.getString(
                  'Restrict which groups can see the internal note'
                )}
                required={
                  !props.disabled &&
                  !props.isNewEvent &&
                  !AuthorizationService.hasPermission(
                    'canSetVisibilityToInternalAll'
                  )
                }
                validateStatus={status}
                help={errors && errors[0]}
                style={{ marginLeft: 50 }}
              >
                <CdSelect
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={gettextCatalog.getString('Please select')}
                  onChange={onGroupChangeHandler}
                  optionLabelProp="label"
                  options={formattedGroups}
                  value={props.value?.groupIds}
                  disabled={props.disabled}
                />
              </Form.Item>
            )}
            <CdRadioWithIcon
              value="internal-all"
              Icon={EventIcons.VisibilityChurch}
              iconSize="small"
              title={gettextCatalog.getString(
                'All users: Every user can see event details'
              )}
              label={gettextCatalog.getString(
                'All users with calendar access can view details of this event. To see sensitive information additional permissions are required.'
              )}
              align="start"
              disabled={
                !props.VisibilityAllowedValues.includes('internal-all') ||
                props.disabled
              }
            />
            <CdRadioWithIcon
              value="internal-group"
              Icon={EventIcons.VisibilityUser}
              iconSize="small"
              title={gettextCatalog.getString(
                'Groups: Specific groups can see event details'
              )}
              label={gettextCatalog.getString(
                'Anyone in these specific groups can view the details of this event. All other users can only see that the booked users and resources are busy.'
              )}
              align="start"
              disabled={
                !props.VisibilityAllowedValues.includes('internal-group') ||
                props.disabled
              }
            />
            {props.value?.visibility === 'internal-group' && (
              <Form.Item
                label={gettextCatalog.getString(
                  'Restrict which groups can see the internal note'
                )}
                required
                validateStatus={status}
                help={errors && errors[0]}
                style={{ marginLeft: 50 }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={gettextCatalog.getString('Please select')}
                  onChange={onGroupChangeHandler}
                  optionLabelProp="label"
                  options={formattedGroups}
                  value={props.value?.groupIds}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={props.disabled}
                  filterOption={(input, option) =>
                    option?.label
                      .toLocaleLowerCase()
                      .includes(input.trim().toLocaleLowerCase())
                  }
                />
              </Form.Item>
            )}
            <CdRadioWithIcon
              value="private"
              Icon={EventIcons.VisibilityLock}
              iconSize="small"
              title={gettextCatalog.getString(
                'Private: Only you and invited users can see event details'
              )}
              label={gettextCatalog.getString(
                'Only you and the invited users can see event details. All other users can only see that the booked users and resources are busy.'
              )}
              align="start"
              disabled={props.disabled}
            />
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};
