import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Button, Divider, Layout } from 'antd';

import FileDetail from '../../../components/file-detail';
import Empty from '../../../components/file-detail/Empty';
import {
  Title,
  Credit,
  Date,
  Size,
  Tags,
  Visibility,
  Author,
  Group,
  FileType,
} from '../../../components/file-detail';
import FormatSelection from '../../../components/format-selection';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';
import { gettextCatalog } from '@/react/services/I18nService';

const { Content, Sider, Footer } = Layout;

const StyledEditButton = styled(Button)`
  margin-right: 10px;
  span {
    margin-left: 8px;
  }
`;

const StyledDeleteButton = styled(Button)`
  span {
    margin-left: 8px;
  }
`;

const StyledDivider = styled(Divider)`
  background: #d2caca;
`;

interface Props {
  item: any;
  isUsedFromEditor: boolean;
  onImportFile: any;
  onEdit: any;
  onDelete: any;
}

const SideBar: React.FC<Props> = ({
  item,
  isUsedFromEditor,
  onImportFile,
  onEdit,
  onDelete,
}) => {
  let imageFormat = 'medium';
  const setImageFormat = (format: string) => {
    imageFormat = format;
  };
  let content;
  if (!item) {
    content = <Empty />;
  } else {
    content = (
      <FileDetail>
        <Layout style={{ background: '#ffffff' }}>
          <Content style={{ height: '71vh', overflowY: 'scroll' }}>
            <Title title={item.title} />
            <Date date={item.createdAt} />
            <Author authorName={item.authorName} />
            <Group groupName={item.groupName} />
            <FileType fileType={item.fileInfo.filetype.translated_type} />
            <Size size={item.fileInfo.filesize} />
            <Visibility visibility={item.visibility} />
            <Credit credit={item.credits} />
            <Tags tags={item.tags} />
            {item.permission.canEdit || item.permission.canDelete ? (
              <StyledDivider style={{ margin: '10px 0' }} />
            ) : null}
            {item.permission.canEdit && (
              <StyledEditButton type="link" onClick={() => onEdit(item)}>
                <FontAwesomeIcon icon={faEdit} />
                <span>{gettextCatalog.getString('Edit')}</span>
              </StyledEditButton>
            )}
            {item.permission.canDelete && (
              <StyledDeleteButton
                type="link"
                onClick={() => onDelete(item)}
                danger
              >
                <FontAwesomeIcon icon={faTrashAlt} />
                <span>{gettextCatalog.getString('Delete')}</span>
              </StyledDeleteButton>
            )}
            {isUsedFromEditor ? (
              <StyledDivider style={{ margin: '10px 0' }} />
            ) : null}
            {isUsedFromEditor ? (
              <FormatSelection
                defaultValue={imageFormat}
                onChange={setImageFormat}
              />
            ) : null}
          </Content>

          <Footer style={{ padding: '12px 12px 0', background: '#ffffff' }}>
            {item.visibility === 'group' ? (
              <CdTooltip
                title={gettextCatalog.getString(
                  'The selected image is private. Please choose an image that is public.'
                )}
                getPopupContainer={() =>
                  document
                    .getElementsByClassName('ant-modal-wrap')
                    .item(0) as HTMLElement
                }
                placement="top"
              >
                <Button
                  type="primary"
                  onClick={() => onImportFile(item, imageFormat)}
                  block
                  disabled
                >
                  {gettextCatalog.getString('Select')}
                </Button>
              </CdTooltip>
            ) : (
              <Button
                type="primary"
                onClick={() => onImportFile(item, imageFormat)}
                block
              >
                {gettextCatalog.getString('Select')}
              </Button>
            )}
          </Footer>
        </Layout>
      </FileDetail>
    );
  }
  return (
    <Sider theme="light" width={300} style={{ borderLeft: '1px solid #eee' }}>
      {content}
    </Sider>
  );
};

export default SideBar;
