import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  deleteRotaScheme,
  getRotaSchemes,
  updateRotaScheme,
} from '@/react/calendar/rota-scheme/service/rotaSchemeService';
import {
  RotaScheme,
  RotaSchemeUpdateRequestBody,
} from '@/react/calendar/rota-scheme/types/rotaScheme.types';

export const useRotaSchemes = () => {
  const { data } = useQuery({
    queryKey: ['rotaSchemes'],
    queryFn: () => getRotaSchemes(),
  });
  return {
    rotas: data,
  };
};

export const useRefreshRotaSchemes = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['rotaSchemes'] }),
    [queryClient]
  );
};

export const useDeleteRotaSchemaMutation = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({ rotaScheme }: { rotaScheme: RotaScheme }) =>
      deleteRotaScheme(rotaScheme.id),
  });
  return { deleteRota: mutateAsync };
};

export const useUpdateRotaSchemeMutation = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({
      id,
      updateBody,
    }: { id: string; updateBody: RotaSchemeUpdateRequestBody }) =>
      updateRotaScheme(id, updateBody),
  });
  return { updateRotaScheme: mutateAsync };
};
