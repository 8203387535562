import NiceModal, { useModal, antdDrawerV5 } from '@ebay/nice-modal-react';
import { ErrorBoundary } from '@sentry/react';
import { Form, Drawer } from 'antd';
import React, { useEffect, Suspense } from 'react';

import { CdErrorPage } from '../../shared/components/cd-error-page/CdErrorPage';
import { CalendarEventTabs } from '../types/event';

import { EventDetailsPageSuspended } from './components/EventDetailsPageSuspended';
import { EventDetailsPage } from './EventDetailsPage';
import { useCloseModal } from './hooks/use-close-modal';
import { NewEventProps } from './hooks/use-event';
import { getEventCopy } from './services/event.service';

export const EventDrawerHOC = (props: {
  eventId?: number;
  startingTab?: CalendarEventTabs;
  $stateParams?: {
    id: string;
    openAddForm: boolean;
  };
}) => {
  const eventId = props.eventId || parseInt(props.$stateParams.id, 10);
  const startingTab =
    props.$stateParams?.openAddForm && CalendarEventTabs.SIGN_UPS;

  useEffect(() => {
    openEventDrawer({
      eventId,
      isMainPage: true,
      startingTab: startingTab,
    });
  }, [eventId, startingTab]);
  return <></>;
};

export type EventDrawerProps = {
  eventId?: number;
  newEvent?: NewEventProps;
  isMainPage?: boolean;
  copyEventId?: number;
  startingTab?: CalendarEventTabs;
};

export const openEventDrawer = async (props: EventDrawerProps) => {
  if (props.copyEventId) {
    const copiedEvent = await getEventCopy(props.copyEventId);

    // posters, invites,
    return NiceModal.show('EventDrawer', {
      newEvent: copiedEvent,
    });
  }

  return NiceModal.show('EventDrawer', {
    ...props,
  });
};

export const EventDrawer = NiceModal.create<EventDrawerProps>(
  ({ eventId, newEvent, isMainPage, startingTab }) => {
    const modal = useModal('EventDrawer');
    const [form] = Form.useForm();
    const { close } = useCloseModal({
      modal,
      form,
      isMainPage,
    });

    return (
      <Drawer
        {...antdDrawerV5(modal)}
        closable={false}
        width="100vw"
        headerStyle={{ borderBottom: '0px' }}
        onClose={() => close()}
      >
        <Suspense fallback={<EventDetailsPageSuspended close={close} />}>
          <ErrorBoundary fallback={<CdErrorPage />}>
            <EventDetailsPage
              eventId={eventId}
              newEvent={newEvent}
              close={close}
              form={form}
              startingTab={startingTab}
            />
          </ErrorBoundary>
        </Suspense>
      </Drawer>
    );
  }
);

NiceModal.register('EventDrawer', EventDrawer);
