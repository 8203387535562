import {
  PeopleAnniversarySettings,
  PeopleAnniversaryUsersToNotify,
} from '../../types/people';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const getAnniversarySettings = async ({
  includeAllAnniversariesField,
}: {
  includeAllAnniversariesField: boolean;
}): Promise<PeopleAnniversarySettings> => {
  const response = await mainApi.get<PeopleAnniversarySettings>(
    `people/people/anniversaries/settings`,
    {
      includeAllAnniversariesField,
    }
  );
  if (response.status === 403) {
    return {
      accessDenied: true,
    };
  }
  if (!response.ok) {
    handleError(response);
    return null;
  }
  return response.data;
};
export const getAnniversaryUsersToNotify = async (
  parishIds: number[]
): Promise<PeopleAnniversaryUsersToNotify[]> => {
  const response = await mainApi.get<PeopleAnniversaryUsersToNotify[]>(
    `people/people/anniversaries/notify-users`,
    { parishIds }
  );
  if (!response.ok) {
    handleError(response);
    return [];
  }
  return response.data;
};
