import {
  Alert,
  Card,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { gettextCatalog } from '../../../../services/I18nService';
import { BlogStylePreview } from '../BlogStylePreview';
import { WidgetInterface } from '../../types/widget.type';
import usePortalWidget from '../../hooks/use-portal-widget';

import { CdEventCategorySelect } from '@/react/calendar/event-details/components/CdEventCategorySelect';
import { CdImagePicker } from '@/react/calendar/event-details/components/CdImagePicker';
import { CdrColorPicker } from '@/react/shared/components/cd-color-picker/CdrColorPicker';
import { useOrganization } from '@/react/organization/hooks/useOrganization';
import { PortalCategoryType } from '@/react/portal/state/portal';
import { Organization } from '@/react/portal/types/portal.type';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';

export default function BlogWidgetForm({
  widgetData,
  widgetForm,
}: {
  widgetData: WidgetInterface;
  widgetForm: FormInstance;
}) {
  const { organizationSettings } = useOrganization();
  const { organizationPortals, selectedPortal, onFormValuesChange } =
    usePortalWidget(widgetData, widgetForm);

  const [includeFrom, changeIncludeFrom] = useState(
    widgetData?.configuration?.collaboration
      ? 'portalCollaboration'
      : 'ownOrganization'
  );

  const initialFormValues: WidgetInterface = {
    ...{
      customizeColorScheme:
        widgetData?.configuration?.cardColor ||
        widgetData?.configuration?.primaryColor
          ? true
          : false,
      configuration: {
        primaryColor: organizationSettings.primaryColor,
        cardColor: organizationSettings.cardColor,
        collaboration: {
          portalId: selectedPortal?.id,
          portalCategoryIds: [],
          organizationIds: [],
        },

        // Unique to Blog Widget
        pageSize: 4,
        itemsPerRow: 2,
        showImage: true,
        showUserFiltering: false,
      },
    },

    // Overwrite with widget data
    ...cloneDeep(widgetData),
  };

  return (
    <Form
      form={widgetForm}
      layout="vertical"
      initialValues={initialFormValues}
      onValuesChange={onFormValuesChange}
      style={{ marginTop: 30 }}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: gettextCatalog.getString('The field is required.'),
          },
        ]}
        label={gettextCatalog.getString('Widget name')}
        extra={gettextCatalog.getString('Internal use only.')}
      >
        <Input size="large" />
      </Form.Item>

      <Divider />

      <Typography.Title level={2} style={{ marginBottom: '20px' }}>
        {gettextCatalog.getString('Blogs to include in the feed')}
      </Typography.Title>

      {!widgetData?.legacy ? (
        <>
          <Form.Item label={gettextCatalog.getString('Include blogs from')}>
            <Radio.Group
              value={includeFrom}
              onChange={(e) => changeIncludeFrom(e.target.value)}
            >
              <Radio value="ownOrganization">
                {gettextCatalog.getString('Own blogs')}
              </Radio>
              <Radio
                value="portalCollaboration"
                disabled={
                  selectedPortal === undefined || selectedPortal === null
                }
              >
                {gettextCatalog.getString('Portal community')}
              </Radio>
            </Radio.Group>
          </Form.Item>

          {includeFrom === 'ownOrganization' ? (
            <>
              <Form.Item
                name={['configuration', 'categoryIds']}
                label={gettextCatalog.getString('Blog categories')}
                extra={gettextCatalog.getString(
                  'Leave empty to include all blog categories.'
                )}
              >
                <CdEventCategorySelect
                  type={PortalCategoryType.BLOG}
                  mode="multiple"
                  placeholder={gettextCatalog.getString('Please select')}
                />
              </Form.Item>
            </>
          ) : (
            includeFrom === 'portalCollaboration' && (
              <>
                <Form.Item
                  label={gettextCatalog.getString('Select portal community')}
                  name={['configuration', 'collaboration', 'portalId']}
                >
                  <CdSelect
                    placeholder={gettextCatalog.getString('Please select')}
                    options={organizationPortals?.portals?.map((portal) => ({
                      label: portal.name,
                      value: portal.id,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  shouldUpdate={(prevValues, currentValue) =>
                    prevValues.configuration?.collaboration?.portalId !==
                    currentValue.configuration?.collaboration?.portalId
                  }
                  noStyle
                >
                  {({ getFieldValue }) =>
                    getFieldValue([
                      'configuration',
                      'collaboration',
                      'portalId',
                    ]) && (
                      <>
                        <Form.Item
                          label={gettextCatalog.getString(
                            'Select portal categories'
                          )}
                          name={[
                            'configuration',
                            'collaboration',
                            'portalCategoryIds',
                          ]}
                          extra={gettextCatalog.getString(
                            'Leave empty to include all portal categories.'
                          )}
                        >
                          <CdSelect
                            mode="multiple"
                            maxTagCount="responsive"
                            placeholder={gettextCatalog.getString(
                              'Please select'
                            )}
                            options={selectedPortal?.categories
                              .filter(
                                (category) =>
                                  category.type === PortalCategoryType.BLOG ||
                                  category.type === null
                              )
                              .map((category) => ({
                                value: category.id,
                                label: category.name,
                              }))}
                          />
                        </Form.Item>

                        <Form.Item
                          label={gettextCatalog.getString(
                            'Include blogs from these organizations'
                          )}
                          name={[
                            'configuration',
                            'collaboration',
                            'organizationIds',
                          ]}
                          extra={gettextCatalog.getString(
                            'Leave empty to include all organizations.'
                          )}
                        >
                          <CdSelect
                            mode="multiple"
                            placeholder={gettextCatalog.getString(
                              'Please select'
                            )}
                            maxTagCount="responsive"
                            options={selectedPortal?.organizations?.map(
                              (organization: Organization) => ({
                                value: organization.id,
                                label: organization.name,
                              })
                            )}
                          />
                        </Form.Item>
                      </>
                    )
                  }
                </Form.Item>
              </>
            )
          )}
        </>
      ) : (
        <Alert
          type="info"
          message={gettextCatalog.getString(
            'This is a legacy widget. You cannot change its contents.'
          )}
        />
      )}

      <Divider />

      <Typography.Title level={2} style={{ marginBottom: '20px' }}>
        {gettextCatalog.getString('Style of blog feed')}
      </Typography.Title>

      <Form.Item
        rules={[
          {
            type: 'integer',
          },
          {
            required: true,
            message: gettextCatalog.getString('The field is required.'),
          },
        ]}
        name={['configuration', 'pageSize']}
        label={gettextCatalog.getString('Number of blogs per page')}
      >
        <InputNumber max={20} min={1} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            type: 'integer',
          },
          {
            required: true,
            message: gettextCatalog.getString('The field is required.'),
          },
        ]}
        name={['configuration', 'itemsPerRow']}
        label={gettextCatalog.getString('Number of blogs per row')}
      >
        <Select
          options={[
            {
              value: 1,
              label: gettextCatalog.getString('1 per row'),
            },
            {
              value: 2,
              label: gettextCatalog.getString('2 per row'),
            },
            {
              value: 3,
              label: gettextCatalog.getString('3 per row'),
            },
          ]}
        />
      </Form.Item>

      <Space style={{ marginBottom: '16px' }}>
        <Typography.Text strong style={{ marginBottom: '16px' }}>
          {gettextCatalog.getString('Image fallback')}
        </Typography.Text>
      </Space>

      <Card
        bodyStyle={{ paddingBottom: '0px' }}
        style={{ marginBottom: '16px' }}
      >
        <Form.Item
          name={['configuration', 'fallBackImageId']}
          label={gettextCatalog.getString('Choose default image')}
          extra={gettextCatalog.getString(
            'Default image if there is no image available for a blog'
          )}
        >
          <CdImagePicker
            entityId={widgetData?.id}
            entityType="Widgetblog"
            canEdit={true}
            defaultImageUrl="https://assets.churchdesk.com/widgetDefaultImage/Blogpost_fallback_image.jpg?class=large"
            canCrop={false}
          />
        </Form.Item>
      </Card>

      <HorizontalFormItem
        name={['configuration', 'showUserFiltering']}
        valuePropName="checked"
        label={gettextCatalog.getString('Allow visitors to filter')}
      >
        <Switch />
      </HorizontalFormItem>
      <HorizontalFormItem
        name="customizeColorScheme"
        valuePropName="checked"
        label={gettextCatalog.getString('Customize color scheme')}
      >
        <Switch />
      </HorizontalFormItem>

      <Form.Item
        shouldUpdate={(prevValues, currentValue) =>
          prevValues.customizeColorScheme !== currentValue.customizeColorScheme
        }
        noStyle
      >
        {({ getFieldValue }) =>
          getFieldValue('customizeColorScheme') && (
            <Card bodyStyle={{ paddingBottom: '0px' }}>
              <Form.Item
                name={['configuration', 'primaryColor']}
                label={gettextCatalog.getString('Primary color')}
              >
                <CdrColorPicker
                  defaultColor={organizationSettings.primaryColor}
                />
              </Form.Item>

              <Form.Item
                name={['configuration', 'cardColor']}
                label={gettextCatalog.getString('Card background color')}
              >
                <CdrColorPicker
                  transparentControls={true}
                  defaultColor={organizationSettings.cardColor}
                />
              </Form.Item>
            </Card>
          )
        }
      </Form.Item>

      <Form.Item
        shouldUpdate
        label={gettextCatalog.getString('Preview')}
        style={{ marginTop: '16px' }}
      >
        {({ getFieldValue }) => (
          <BlogStylePreview
            primaryColor={getFieldValue('configuration').primaryColor}
            cardColor={getFieldValue('configuration').cardColor}
            pageSize={getFieldValue('configuration').pageSize}
            itemsPerRow={getFieldValue('configuration').itemsPerRow}
            settings={organizationSettings}
          />
        )}
      </Form.Item>
    </Form>
  );
}

const HorizontalFormItem = styled(Form.Item).attrs({
  wrapperCol: { span: 8 },
  labelCol: { span: 16 },
})`
  &&&& {
    margin-bottom: 8px;
    .ant-form-item-row {
      flex-direction: row;

      .ant-form-item-label {
        margin: auto;
        padding-bottom: 0;
      }
      .ant-form-item-control {
        align-items: end;
      }
    }
  }
`;
