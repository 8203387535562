import _ from 'lodash';
import moment from 'moment';

import I18nService from '@/react/services/I18nService';

class RotasComponent {
  constructor(
    $q,
    $http,
    $state,
    $stateParams,
    amMoment,
    Me,
    Taxonomies,
    Groups,
    Shifts,
    Analytics,
    RotasLinks,
    dateFormatsLookup,
    $uibModal,
    gettextCatalog,
    ColumnManager,
    cdApp,
    toastr
  ) {
    'ngInject';

    this.$q = $q;
    this.$http = $http;
    this.$state = $state;
    this.amMoment = amMoment;
    this.Me = Me;
    this.$stateParams = $stateParams;
    this.Taxonomies = Taxonomies;
    this.Groups = Groups;
    this.Shifts = Shifts;
    this.Analytics = Analytics;
    this.dateFormatsLookup = dateFormatsLookup;
    this.$uibModal = $uibModal;
    this.gettextCatalog = gettextCatalog;
    this.ColumnManager = ColumnManager;
    this.cdApp = cdApp;
    this.RotasLinks = RotasLinks;
    this.toastr = toastr;
  }

  $onInit() {
    const { $stateParams, dateFormatsLookup, Taxonomies, Groups } = this;
    /**
     * Date filter configuration
     */
    this.defaultDateFilter = 'month';
    this.quickDateSuggestions = this.getQuickDateSuggestions();

    // Make sure it is an array
    const toArray = (value) => (_.isArray(value) ? value : [value]);

    /**
     * Initialize the filters from the state parameters
     */
    const {
      start,
      end,
      categories,
      tasks,
      resources,
      rotaLinkId,
      reloadRota,
      filtersUpdated,
      columnName,
      eventsWithoutResourcesInChurchIds,
    } = $stateParams;
    this.rotaLinkId = rotaLinkId;

    this.isNewRotaScheme =
      !reloadRota &&
      !rotaLinkId &&
      !this.isDefaultRotaView &&
      !this.isCurrentUserScheme &&
      !this.isPersonalRotaScheme;
    this.filtersUpdated = filtersUpdated === 'true';
    this.columnName = columnName || null;
    this.filters = {
      start,
      end,
      categories: _.map(toArray(categories || []), _.parseInt),
      tasks: _.map(toArray(tasks || []), _.parseInt),
      resources: _.map(toArray(resources || []), _.parseInt),
      eventsWithoutResourcesInChurchIds: _.map(
        toArray(eventsWithoutResourcesInChurchIds || []),
        _.parseInt
      ),
    };

    // If it is a rota scheme load
    if (this.rotaLink && !reloadRota) {
      this.filters = _.extend(this.filters, {
        categories: _.get(this.rotaLink, 'settings.taxonomyIds', []),
        tasks: _.get(this.rotaLink, 'settings.taskIds', []),
        resources: _.get(this.rotaLink, 'settings.resourceIds', []),
        eventsWithoutResourcesInChurchIds: _.get(
          this.rotaLink,
          'settings.eventsWithoutResourcesInChurchIds',
          []
        ),
      });
    }

    this.datepickerOptions = {
      endDate: {
        minDate: this.filters.start,
      },
    };

    /**
     * If it's a public rota, the `Hide notes` button will not be shown unless the backend returns so
     */
    this.allowNotesToggleButton = this.isPublic ? false : true;

    /**
     * Whether the notes are shown in the rotas
     */
    this.hideNotes = this.isPublic ? true : false;

    // Special datepicker format.
    this.datepickerFormat = dateFormatsLookup.getFormat();

    // Custom datepicker options.
    this.dateOptions = {
      startingDay: 1,
    };

    if (!this.isPublic) {
      /**
       * Load resources, event categories & groups
       */
      Taxonomies.query({ type: 'event' }).$promise.then((categories) => {
        this.categories = categories;
      });
      Groups.query().$promise.then((groups) => {
        this.groups = groups;
      });
    }

    /**
     * Initial load of rotas
     */
    this.loadRotas(true);
  }

  uiOnParamsChanged(newParams, $transitions) {
    const {
      start,
      end,
      categories = [],
      tasks = [],
      resources = [],
      eventsWithoutResourcesInChurchIds,
    } = $transitions.params();

    this.filters.start = start;
    this.filters.end = end;
    this.filters.categories = categories;
    this.filters.tasks = tasks;
    this.filters.resources = resources;
    this.filters.eventsWithoutResourcesInChurchIds =
      eventsWithoutResourcesInChurchIds;

    this.loadRotas(false);
  }

  getQuickDateSuggestions() {
    const { gettextCatalog } = this;
    return [
      {
        key: 'month',
        label: gettextCatalog.getString('Only next month'),
        from: moment().startOf('day').toDate(),
        until: moment().add(1, 'month').endOf('day').toDate(),
      },

      {
        key: '3month',
        label: gettextCatalog.getString('Next 3 months'),
        from: moment().startOf('day').toDate(),
        until: moment().add(3, 'month').startOf('day').toDate(),
      },
    ];
  }

  /**
   * Page header title
   */
  getPageTitle() {
    const { gettextCatalog } = this;
    if (this.isPublic) {
      return this.organizationName || gettextCatalog.getString('Rotas');
    }

    if (this.rotaLink) return this.rotaLink.name;

    if (this.isDefaultRotaView) {
      return gettextCatalog.getString('All rotas (default rota scheme)');
    }
    if (this.isCurrentUserScheme || this.isPersonalRotaScheme) {
      return gettextCatalog.getString('Print my scheme');
    }

    if (this.isNewRotaScheme) {
      return gettextCatalog.getString('New rota scheme');
    }

    return gettextCatalog.getString('Manage rotas');
  }

  /**
   * Page header subtitle
   */
  getPageSubtitle() {
    if (this.isPublic) {
      return this.rotaTitle;
    }
  }

  /**
   * Print the rota
   */
  print() {
    const { Analytics } = this;
    window.print();
    Analytics.sendFeatureEvent('print rota scheme', { module: 'planning' });
  }

  openSubscribeIcsModal() {
    const { $uibModal, gettextCatalog, cdApp, $q, $http } = this;
    $uibModal.open({
      component: 'cdCalendarFeedModal',
      resolve: {
        feedName: () => gettextCatalog.getString('Subscribe to my rota'),
        feedId: () => cdApp.me.id,
        feedType: () => 'user',
        showPublicFeed: () => false,
        token: () =>
          $q((resolve, reject) => {
            $http
              .get(`${cdApp.config.api.main}/ical/token`)
              .then((res) => resolve(res.data))
              .catch(reject);
          }),
      },
    });
  }

  /**
   * Get the selected filter label
   *
   * @param {Array} filterName The name of the filter
   */
  getFilterLabel(filterName) {
    const { gettextCatalog } = this;

    const total = _.size(_.get(this.filters, filterName));

    switch (filterName) {
      case 'tasks':
        return gettextCatalog.getPlural(total, '1 rota', '{{ $count }} rotas', {
          $count: total,
        });

      case 'categories':
        return gettextCatalog.getPlural(
          total,
          '1 category',
          '{{ $count }} categories',
          {
            $count: total,
          }
        );
    }
  }

  /**
   * Set the selected filter items
   *
   * @param {Array} filterName The name of the filter
   * @param {Array} selectedItems The filter items that are selected
   */
  setSelectedFilter(filterName, selectedItems) {
    this.filters[filterName] = selectedItems;
    this.filtersUpdated = true;
  }

  /**
   * If the user selected filters and hasn't applied them yet, we should allow them to apply them
   */
  canApplyFilters() {
    const { $stateParams } = this;

    return _.some(
      [
        'start',
        'end',
        'categories',
        'tasks',
        'resources',
        'eventsWithoutResourcesInChurchIds',
      ],

      (filter) => !_.isEqual(this.filters[filter], $stateParams[filter])
    );
  }

  /**
   * Update the URL and state with the selected filters in the UI
   */
  applySelectedFilters() {
    const { $state, filtersUpdated, columnName } = this;

    const {
      start,
      end,
      categories,
      tasks,
      resources,
      eventsWithoutResourcesInChurchIds,
    } = this.filters;

    $state.go($state.current, {
      start,
      end,
      categories,
      tasks,
      resources,
      eventsWithoutResourcesInChurchIds,
      reloadRota: true,
      filtersUpdated,
      columnName,
    });
  }

  /**
   * A callback to be called when columuns are changed
   *
   */
  onColumnChange() {
    this.filtersUpdated = true;
  }

  /**
   * Set the selected date filter
   *
   * @param {Date} from The beginning of the interval to filter events
   * @param {Date} until The end of the interval to filter events
   */
  setDateFilter(from, until) {
    this.setSelectedFilter('start', from);
    this.setSelectedFilter('end', until);
  }

  loadRotas(showLoader = false) {
    const {
      $stateParams,
      ColumnManager,
      gettextCatalog,
      Shifts,
      Me,
      amMoment,
    } = this;

    /**
     * Only toggle this flag in the initial load
     */
    if (showLoader) {
      this.isLoadingRotas = true;
    } else {
      this.isUpdatingRotas = true;
    }

    /**
     * Wait for the Me endpoint to be resolved in order to get the organization's language if the user
     * is not logged in. Otherwise, it will be set from the user's browser
     * @see app.config.js
     */
    Me.finally(() => {
      const getRotas = this.isPersonalRotaScheme
        ? Shifts.getPersonalPublicRota({
            startDate: this.filters.start,
            endDate: this.filters.end,
            publicUserToken: $stateParams['token'],
          })
        : this.isPublic
          ? Shifts.getPublicRota({
              startDate: this.filters.start,
              endDate: this.filters.end,
              rotaId: $stateParams.rotaId,
            })
          : Shifts.getRotas({
              startDate: this.filters.start,
              endDate: this.filters.end,
              taxonomyIds: this.filters.categories,
              resourceIds: this.filters.resources,
              eventsWithoutResourcesInChurchIds:
                this.filters.eventsWithoutResourcesInChurchIds,
              taskIds: this.filters.tasks,
              type: this.isCurrentUserScheme ? 'user' : 'all',
            });

      getRotas.$promise
        .then(({ events, tasks, name, allowShowNotes, organization }) => {
          this.rotas = events;

          if (this.isPublic) {
            // Show notes if allowed in public rota or in personal rota scheme
            this.allowNotesToggleButton =
              allowShowNotes || this.isPersonalRotaScheme;

            // Name for public rota
            this.rotaTitle = name;
            this.organizationName = _.get(organization, 'name');

            if (!this.organizationLanguage) {
              // Set translation language
              this.organizationLanguage = _.get(organization, 'language');
              I18nService.setCurrentLanguage(this.organizationLanguage);

              // Set moment locale
              amMoment.changeLocale(this.organizationLanguage);
            }

            // Update translations for quick date suggestions
            this.quickDateSuggestions = this.getQuickDateSuggestions();
          }

          this.tasks = _.map(tasks, (task) => ({
            id: task.id,
            name: task.title,
            color: task.color,
            groupId: task.groupId,
            group: task.group,
          }));

          const displayTaskIds = _.get(
            this.rotaLink,
            'settings.displayTaskIds',
            _.map(tasks, 'id')
          );

          const columns = _(tasks)
            .sortBy('title')
            .map((task) => ({
              property: task.id,
              taskId: task.id,
              label: task.title,
              isVisible: _.includes(displayTaskIds, task.id),
              group: gettextCatalog.getString(
                'Rotas',
                null,
                'Column manger grouping'
              ),
            }))
            .value();
          this.columns = columns;
          // Configure the column manager with those columns
          if (!this.columnManager) {
            const name = this.rotaLink
              ? `rotaTable-${this.rotaLink.id}`
              : `rotaTable-${Date.now()}`;
            if (!this.columnName) {
              this.columnName = name;
            }
            this.columnManager = new ColumnManager({
              name: this.columnName,
              columns,
              placeholder: gettextCatalog.getString('Search for rota...'),
            });
          }
          this.columns = this.columnManager.columns;
          this.columnManager.onChange = () => {
            this.columns = this.columnManager.columns;
          };
        })
        .finally(() => {
          this.isLoadingRotas = false;
          this.isUpdatingRotas = false;
        });
    });
  }

  /**
   * Create a new event task and add it to a new shift
   */
  createRota() {
    const { $uibModal, gettextCatalog } = this;
    $uibModal
      .open({
        component: 'createEventTaskModal',
        windowClass: 'modal-ui-select',
      })
      .result.then((task) => {
        this.columnManager.addColumn({
          property: task.id,
          label: task.title,
          isVisible: true,
          group: gettextCatalog.getString(
            'Rotas',
            null,
            'Column manger grouping'
          ),
        });

        this.loadRotas(false);
      });
  }

  /**
   * Create a public rota from the settings selected by the user and share it via a URL.
   */
  createPublicRota() {
    const { $uibModal } = this;

    $uibModal
      .open({
        component: 'cdCreatePublicRotaModal',
        resolve: {
          id: () => this.rotaLinkId,
          name: () => (this.rotaLink ? this.rotaLink.name : ''),
          displayTaskIds: () =>
            _.map(
              _.filter(this.columnManager.columns, { isVisible: true }),
              'taskId'
            ),

          tasks: () => this.filters.tasks,
          categories: () => this.filters.categories,
          resources: () => this.filters.resources,
          eventsWithoutResourcesInChurchIds: () =>
            this.filters.eventsWithoutResourcesInChurchIds,
          hideNotes: () =>
            this.rotaLink ? !this.rotaLink.settings.includeNotes : '',
          useInitials: () =>
            this.rotaLink ? this.rotaLink.settings.useInitials : '',
          isSchemeCreate: () => true,
        },
      })
      .result.then(() => {
        this.filtersUpdated = false;
      });
  }

  /**
   * Update a public rota when filters are updated
   */
  updatePublicRota() {
    const { RotasLinks, toastr, gettextCatalog } = this;
    const rotaData = {
      id: this.rotaLinkId,
      name: this.rotaLink ? this.rotaLink.name : '',
      displayTaskIds: _.map(
        _.filter(this.columnManager.columns, { isVisible: true }),
        'taskId'
      ),

      taskIds: this.filters.tasks,
      taxonomyIds: this.filters.categories,
      resourceIds: this.filters.resources,
      eventsWithoutResourcesInChurchIds:
        this.filters.eventsWithoutResourcesInChurchIds,
      showNotes: this.rotaLink ? this.rotaLink.settings.includeNotes : '',
      showInitials: this.rotaLink ? this.rotaLink.settings.useInitials : '',
    };

    const rota = new RotasLinks(rotaData);
    rota.$save(() => {
      toastr.success(gettextCatalog.getString('Successfully saved rota.'));
      this.filtersUpdated = false;
    });
  }
}

RotasComponent.$inject = [
  '$q',
  '$http',
  '$state',
  '$stateParams',
  'amMoment',
  'Me',
  'Taxonomies',
  'Groups',
  'Shifts',
  'Analytics',
  'RotasLinks',
  'dateFormatsLookup',
  '$uibModal',
  'gettextCatalog',
  'ColumnManager',
  'cdApp',
  'toastr',
];

angular.module('cdApp.intranet').component('cdRotasState', {
  templateUrl: '@/app/intranet/shifts/rotas/rotas.component.html',
  controller: RotasComponent,
  bindings: {
    isPublic: '<',
    rotaLink: '<',
    isDefaultRotaView: '<',
    isCurrentUserScheme: '<',
    isPersonalRotaScheme: '<',
  },
});
