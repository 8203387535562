import { useQuery } from '@tanstack/react-query';
import { compact, orderBy } from 'lodash';

import { Intention } from '../models/intention';

import { mainApi } from '@/react/api';

export const useFetchEventIntentions = (calendarId: number) =>
  useQuery({
    queryKey: ['useFetchEventIntentions', calendarId],
    queryFn: async () => {
      const response = await mainApi.get<Intention[]>(
        `/intention/intentions/assigned/${calendarId}`
      );
      if (response.ok) {
        // Sort intentions with persolviet first and then by intentionText
        return compact(
          orderBy(
            response.data as Intention[],
            ['priority', 'referenceNumber', 'intentionText'],
            ['asc', 'asc', 'asc']
          )
        );
      }
      throw response.data;
    },
  });
