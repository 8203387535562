import React, { useEffect } from 'react';
import { Form, Row, Typography } from 'antd';
import { isEmpty } from 'lodash';

import { createCdDrawer } from '../../shared/components/cd-drawer/CdDrawer';
import { gettextCatalog } from '../../services/I18nService';
import { ModalType } from '../../shared/components/cd-modal-new/CdModal2';

import { TimeRegistrationCalendar } from './TimeRegistrationCalendar';
import { useTimeRegistrationDrawer } from './hooks/useTimeRegistrationDrawer';
import { WorkingHours } from './WorkingHours';
import { StandardWorkingHours } from './StandardWorkingHours';

export const showTimeRegistrationDrawer = createCdDrawer(
  ({ setDrawerProps }) => {
    const {
      selectedDate,
      setSelectedDate,
      timeRegistrationCalendarData,
      timeRegistrationForm,
      onHoursSaveHandler,
      initialValues,
      selectedDateInsight,
      standardWorkingHoursData,
      hasChanges,
      setHasChanges,
    } = useTimeRegistrationDrawer();

    useEffect(() => {
      setDrawerProps({
        title: gettextCatalog.getString('Time registration'),
        width: 500,
        modalType: ModalType.POPUP,
        onOk: async () => {
          await onHoursSaveHandler(selectedDate);
          return { preventClose: true };
        },
        okText: gettextCatalog.getString('Save'),
        hideCancel: true,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDrawerProps, selectedDate, hasChanges, standardWorkingHoursData]);

    const onValuesChange = React.useCallback(
      (value) => {
        if (value.comment) {
          setHasChanges(true);
        }
      },
      [setHasChanges]
    );

    return (
      <Form
        colon={false}
        form={timeRegistrationForm}
        initialValues={initialValues}
        style={{ width: '100%' }}
        onValuesChange={onValuesChange}
      >
        {isEmpty(standardWorkingHoursData) ? (
          <>
            <Typography.Title level={2}>
              {gettextCatalog.getString('Add your standard working hours')}
            </Typography.Title>
            <StandardWorkingHours />
          </>
        ) : (
          <Row
            gutter={[12, 12]}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <TimeRegistrationCalendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              userTimeRegistrationCalendar={timeRegistrationCalendarData}
            />

            <WorkingHours
              selectedDateInsight={selectedDateInsight}
              timeRegistrationForm={timeRegistrationForm}
              standardWorkingHoursData={standardWorkingHoursData}
            />
          </Row>
        )}
      </Form>
    );
  }
);
