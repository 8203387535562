import NiceModal, { antdModalV5, useModal } from '@ebay/nice-modal-react';
import { Modal, Radio, Space } from 'antd';
import React, { useState } from 'react';

import { EventType } from '@/react/calendar/models/calendar';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdRadioWithIcon } from '@/react/shared/components/cd-radio-with-icon/CdRadioWithIcon';
import { EventIcons } from '@/react/shared/components/Icons';
import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

export interface ResultEditRepeatedEventModal {
  updateAll?: boolean;
  abort?: boolean;
}

export type PropsEditRepeatedEventModal = {
  type: EventType;
  timeChanged: boolean;
};

export const EditRepeatedEventModal = NiceModal.create(
  (props: PropsEditRepeatedEventModal) => {
    const modal = useModal('EditRepeatedEventModal');
    const [shouldUpdateAllInSeries, setShouldUpdateAllInSeries] =
      useState(false);

    return (
      <Modal
        {...{
          ...antdModalV5(modal),
          title: gettextCatalog.getString('Edit recurring event'),
          bodyStyle: { padding: '26px 24px' },
          okButtonProps: {
            danger: shouldUpdateAllInSeries && props.timeChanged,
          },
          onOk: () => {
            modal.resolve({
              updateAll: shouldUpdateAllInSeries,
            } as ResultEditRepeatedEventModal);
            modal.hide();
          },
          onCancel: () => {
            modal.resolve({ abort: true } as ResultEditRepeatedEventModal);
            modal.hide();
          },
          wrapClassName: 'edit-repeated-event-modal',
        }}
      >
        <Space direction="vertical" size="middle">
          <Radio.Group
            onChange={(e) => setShouldUpdateAllInSeries(e.target.value)}
            value={shouldUpdateAllInSeries}
          >
            <Space direction="vertical">
              <CdRadioWithIcon
                value={false}
                label={gettextCatalog.getString('This event')}
                Icon={EventIcons.SingleRepeatedEvents}
                align="center"
                iconSize="large"
              />

              <CdTooltip
                title={
                  props.timeChanged &&
                  gettextCatalog.getString(
                    'You have changed date, start or end time for this event. As a result, we have disabled saving for this and all following events, as this would overwrite all user bookings, rota planning, assigned intentions etc.'
                  )
                }
              >
                <div>
                  <CdRadioWithIcon
                    value={true}
                    label={gettextCatalog.getString(
                      'This and following events'
                    )}
                    Icon={EventIcons.MultipleRepeatedEvents}
                    disabled={props.timeChanged}
                    align="center"
                    iconSize="large"
                  />
                </div>
              </CdTooltip>
            </Space>
          </Radio.Group>
          {/* Removed for now, since it should not be possible for the user to change the time of a repeated event. 
            * Story: 19799
           props.timeChanged && shouldUpdateAllInSeries && (
            <Alert
              message={gettextCatalog.getString('Changes will be lost')}
              description={
                <Space direction="vertical">
                  {props.type === EventType.Event &&
                    gettextCatalog.getString(
                      `You're changing the time or repeat rule of a repeated event and will lose any changes made to any of the individual recurring events.`
                    )}
                  {props.type === EventType.Absence &&
                    gettextCatalog.getString(
                      `You're changing the time or repeat rule of a repeated absence and will lose any changes made to any of the individual recurring absences.`
                    )}
                  {gettextCatalog.getString(`This will include:`)}

                  {props.type === EventType.Event && (
                    <ul>
                      <li>{gettextCatalog.getString(`Booked users`)}</li>
                      <li>{gettextCatalog.getString('All rota entries')}</li>
                      <li>{gettextCatalog.getString('Booked resources')}</li>
                      <li>{gettextCatalog.getString('Internal notes')}</li>
                      <li>{gettextCatalog.getString('Public description')}</li>
                    </ul>
                  )}
                  {props.type === EventType.Absence && (
                    <ul>
                      <li>{gettextCatalog.getString(`Comments`)}</li>
                      <li>{gettextCatalog.getString('Substitute')}</li>
                    </ul>
                  )}
                  {props.type === EventType.Event &&
                    gettextCatalog.getString(
                      'Avoid this by not changing the time or repeat rule of the event.'
                    )}
                  {props.type === EventType.Absence &&
                    gettextCatalog.getString(
                      'Avoid this by not changing the time or repeat rule of the absence.'
                    )}
                </Space>
              }
              type="warning"
              showIcon
            />
          )
            */}
        </Space>
      </Modal>
    );
  }
);
