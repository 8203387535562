import { react2angular } from 'react18-react2angular';

import { AnniversaryPageWraper } from '../../../../../react/people/pages/AnniversaryPage';
import sharedContext from '../../../../shared/components/shared-context-root/shared-context-root.component';

angular
  .module('cdApp.people')
  .component(
    'cdrAnniversary',
    react2angular(sharedContext.use(AnniversaryPageWraper), [], [])
  );
