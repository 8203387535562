import React from 'react';
import { Space } from 'antd';

import { gettextCatalog } from '../../services/I18nService';

import {
  CdSelect,
  CdSelectProps,
} from '@/react/shared/components/cd-select/CdSelect';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { User } from '@/react/user/types/User.types';

interface CdUserSelectProps
  extends Omit<CdSelectProps, 'options' | 'placeholder'> {
  users: User[];
}

export const CdUserSelect = (props: CdUserSelectProps) => (
  <CdSelect
    placeholder={gettextCatalog.getString('Choose a user.')}
    options={props.users.map((user) => ({
      label: (
        <Space>
          <CdUserAvatar picture={user.picture} size={30} />
          {user.name || user.email}
        </Space>
      ),
      value: user.id,
      name: user.name,
      email: user.email,
    }))}
    filterOption={(input, option) =>
      option?.name?.toLowerCase().includes(input.toLowerCase()) ||
      option?.email?.toLowerCase().includes(input.toLowerCase())
    }
    filterSort={(a, b) => (a.name || a.email).localeCompare(b.name || b.email)}
    {...props}
  />
);
