import { chain } from 'lodash';
import { useRecoilValue, waitForAll } from 'recoil';
import { Form } from 'antd';

import {
  sogndkCategoriesQuery,
  sogndkChurchesQuery,
  sogndkPriestsQuery,
  sogndkParishQuery,
} from '@/react/calendar/store/events/event';

export default function useSognDK() {
  const [
    queryedCategories = [],
    queryedChurchIds = [],
    queryedPriests = [],
    queryedParishes = [],
  ] = useRecoilValue(
    waitForAll([
      sogndkCategoriesQuery,
      sogndkChurchesQuery,
      sogndkPriestsQuery,
      sogndkParishQuery,
    ])
  );
  const selectedCategoryId = Form.useWatch(['sogndk', 'categoryId']);
  const getSongDkSelectedCategoryType = (selectedCategoryId: number) => {
    const type = queryedCategories.find(
      (category) => category.sognDkCategoryId === selectedCategoryId
    )?.sognDkCategoryType;
    return type;
  };
  const sognDK = {
    categoryTypes: chain(queryedCategories)
      .groupBy('sognDkCategoryType')
      .map((categories, name) => ({
        name,
        categories,
      }))
      .value(),
    parishes: queryedParishes.map((parish) => ({
      ...parish,
      churchs: queryedChurchIds.filter(
        (church) => church.sogneName === parish.name
      ),
      priests: queryedPriests.filter(
        (priest) => priest.sogneName === parish.name
      ),
    })),
  };

  return {
    sognDK,
    selectedCategoryType: getSongDkSelectedCategoryType(selectedCategoryId),
  };
}
