import { Form } from 'antd';
import React from 'react';

import { EventsRotaTable } from '../components/rotas/EventsRotaTable';
import { EventLoadingState } from '../hooks/use-event';

export const RotasAndIntentionsTab = (props: {
  calendarId: number;
  onSave: ({ notify }: { notify: boolean }) => Promise<void>;
  saveProcessing: EventLoadingState;
  isViewingPreviousEvent: boolean;
}) => (
  <Form.Item
    shouldUpdate={(prevValues, curValues) =>
      prevValues.date.startDate !== curValues.date.startDate ||
      prevValues.date.endDate !== curValues.date.endDate ||
      prevValues.date.rrule !== curValues.date.rrule ||
      prevValues.date.allDay !== curValues.date.allDay ||
      prevValues.title !== curValues.title ||
      prevValues.resourcesLocation.churchIds !==
        curValues.resourcesLocation.churchIds ||
      prevValues.mainCategory !== curValues.mainCategory ||
      prevValues.taxonomies !== curValues.taxonomies
    }
    noStyle
  >
    {({ getFieldValue }) => {
      let categoryIds = [];
      const taxonomies = getFieldValue('taxonomies');
      const mainCategory = getFieldValue('mainCategory');
      if (mainCategory) categoryIds.push(mainCategory);
      if (taxonomies) {
        categoryIds = taxonomies.includes(mainCategory)
          ? taxonomies
          : categoryIds.concat(taxonomies);
      }
      return (
        <Form.Item name="rotasAndIntentions">
          <EventsRotaTable
            calendarId={props.calendarId}
            eventTitle={getFieldValue('title')}
            eventDate={getFieldValue('date')}
            eventChurchIds={getFieldValue('resourcesLocation').churchIds}
            categoryIds={categoryIds}
            onSave={props.onSave}
            saveProcessing={props.saveProcessing}
            isViewingPreviousEvent={props.isViewingPreviousEvent}
          />
        </Form.Item>
      );
    }}
  </Form.Item>
);
