import { Space, Input, Button } from 'antd';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { gettextCatalog } from '../../../services/I18nService';

import { CdCopyIcon } from '@/react/shared/components/Icons';
import NotificationService from '@/react/services/NotificationService';

export default function CopyInput({ value }) {
  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input value={value} disabled style={{ cursor: 'default' }} />
      <CopyToClipboard
        text={value}
        onCopy={() =>
          NotificationService.notifySuccess(
            gettextCatalog.getString('Copied to clipboard')
          )
        }
      >
        <Button type="primary" icon={<CdCopyIcon />}>
          {gettextCatalog.getString('Copy')}
        </Button>
      </CopyToClipboard>
    </Space.Compact>
  );
}
