import { useMutation } from '@tanstack/react-query';

import { reSubscribeToList } from '../store/reSubscribeToLists';

import { gettextCatalog } from '@/react/services/I18nService';
import { handleSuccessMessage } from '@/react/shared/utils';
import { navigate } from '@/react/services/StateServiceFactory';

export function useReSubscribeToLists(contactId: number) {
  const { mutateAsync } = useMutation({
    mutationFn: reSubscribeToList,
    onSuccess: () => {
      handleSuccessMessage(
        gettextCatalog.getString('The information was saved successfully.')
      );
      navigate(
        `app.private.people.contacts.view`,
        { id: contactId, step: 'settings' },
        { reload: true }
      );
    },
  });
  return {
    reSubscribeToList: mutateAsync,
  };
}
