import { create } from 'apisauce';
import _ from 'lodash';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import AuthenticationService from '../services/AuthenticationService';
import cdApp from '../config';

// Page pagination
export interface ApiSearchParams extends Record<string, any> {
  pageNumber: number;
  limit: number;
  extraData?: any;
}

export interface ApiSearchResult<T> {
  items: T[];
  total: number;
}

// Cursor pagination
export interface ApiCursorSearchParams extends Record<string, any> {
  cursor: string;
  limit: number;
  extraData?: any;
}

export interface ApiCursorSearchResult<T> {
  items: T[];
  cursor: string;
}

/** ApiSauce would parse query object as:
 * filters=%7B%22status%22:[%22active%22]%7D  -->  filters={"status":["active"]}
 * instead of:
 * filters%5Bstatus%5D%5B0%5D=active  -->  filters[status][0]: active
 * which express (qs.js) expects. Joi previously supported parsing string to object automatically
 * but this has been removed: https://github.com/hapijs/joi/issues/2124 */
const paramsSerializer = (params: any) =>
  qs.stringify(_.omitBy(params, _.isNil));

const mainApi = create({ baseURL: cdApp.config?.api.main, paramsSerializer });
const formsApi = create({ baseURL: cdApp.config?.api.forms, paramsSerializer });
const contributionsApi = create({
  baseURL: cdApp.config?.api.payments,
  paramsSerializer,
});
const portalWidgetService = create({
  baseURL: cdApp.config?.api.portal,
  paramsSerializer,
});
const partnershipApi = create({
  baseURL: cdApp.config?.api.partners,
  paramsSerializer,
});
const transformRequest = (request: AxiosRequestConfig): void => {
  request.params = request.params || {};
  request.headers = request.headers || {};

  const skipAuthorization =
    _.get(request.params, 'skipAuthorization', false) === true;
  delete request.params.skipAuthorization;

  if (!skipAuthorization) {
    // append organizationId to all internal API requests
    if (request.url.indexOf('organizationId=') === -1) {
      request.params.organizationId =
        request.params.organizationId ||
        _.get(window, 'churchdeskOrganizationId');
    }
    const accessToken = AuthenticationService.getAccessToken();
    if (accessToken) {
      request.headers['Authorization'] =
        request.headers['Authorization'] || `Bearer ${accessToken}`;
    }
  }

  // App version
  request.headers['X-CD-Version'] = new Date(cdApp.version).toUTCString();

  // Logged in user and masquerading user, if applicable
  if (cdApp.me) {
    request.headers['X-CD-User'] = cdApp.me.id;

    if (cdApp.me.masquerading) {
      request.headers['X-CD-Masquerading-User'] = cdApp.me.masqueradingUser;
    }
  }
};

mainApi.addRequestTransform(transformRequest);
formsApi.addRequestTransform(transformRequest);
contributionsApi.addRequestTransform(transformRequest);
partnershipApi.addRequestTransform(transformRequest);

export {
  mainApi,
  partnershipApi,
  formsApi,
  contributionsApi,
  portalWidgetService,
};
