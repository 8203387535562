import { useCallback, useMemo } from 'react';
import { FormInstance } from 'antd/es/form/hooks/useForm';

import {
  RotaScheme,
  RotaSchemeSettingsFormData,
} from '@/react/calendar/rota-scheme/types/rotaScheme.types';
import NotificationService from '@/react/services/NotificationService';
import { useUpdateRotaSchemeMutation } from '@/react/calendar/rota-scheme/store/rotaHooks';

export const useSettingsForm = (
  rotaScheme: RotaScheme,
  formInstance: FormInstance<RotaSchemeSettingsFormData>
) => {
  const initialValues = useMemo(
    (): RotaSchemeSettingsFormData => ({
      name: rotaScheme.name,
      settings: [
        ...(rotaScheme.settings.useInitials ?? false
          ? ['useInitials' as const]
          : []),
        ...(rotaScheme.settings.includeNotes ?? true
          ? []
          : ['hideNotes' as const]),
      ],
    }),
    [rotaScheme]
  );

  const { updateRotaScheme } = useUpdateRotaSchemeMutation();

  const onSave = useCallback(async () => {
    await formInstance.validateFields();

    // the above will throw if there are any validation errors
    // we can assume here that form is valid

    const values = formInstance.getFieldsValue();
    await updateRotaScheme({
      id: rotaScheme.id,
      updateBody: {
        displayTaskIds: rotaScheme.settings.displayTaskIds,
        taxonomyIds: rotaScheme.settings.taxonomyIds,
        resourceIds: rotaScheme.settings.resourceIds,
        taskIds: rotaScheme.settings.taskIds,
        eventsWithoutResourcesInChurchIds: [],
        name: values.name,
        showInitials: values.settings.includes('useInitials'),
        showNotes: !values.settings.includes('hideNotes'),
      },
    });
    NotificationService.notifySuccess('Rota scheme updated.');
  }, [formInstance, rotaScheme, updateRotaScheme]);

  return { initialValues, onSave };
};
