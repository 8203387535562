import {
  Alert,
  Avatar,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  theme,
} from 'antd';
import moment from 'moment';
import React, { Suspense } from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { truncate } from 'lodash';

import { Shift } from '../../hooks/use-add-user-to rota';
import { CdVerticalSpace } from '../../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdParishSelect } from '@/react/shared/components/cd-perish-select/CdParishSelect';
import { CdGroupUserSelect } from '@/react/shared/components/cd-group-user-select/CdGroupUserSelect';
import {
  CdCalendar,
  CdClose,
  CdExclamationTriangleIcon,
  CdTime,
  CdUserAvatarLight,
  CdUsersClock,
  EventIcons,
} from '@/react/shared/components/Icons';
import { GetOrganizationUser } from '@/react/organization/store/organization';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

const { TextArea } = Input;
const Container = styled.div`
 padding: 0px;
`;
const Note = styled(TextArea)`
   :where(.css-dev-only-do-not-override-1g6mqjm).ant-input-disabled,
   :where(.css-dev-only-do-not-override-1g6mqjm).ant-input[disabled]{
    color: #000;
  }
`;
const SelectContainer = styled(Form.Item)`
margin-bottom: 0;
`;
const RotaDutyTitle = styled(Typography.Text)`
  &&&& {
    font-size: 22px;
    font-weight: 600;
  }
`;

const RowContainer = styled(Row)`
  &&&& {
    height: auto;
    display: flex;
    padding: 8px;
    align-items: center;
    width: 100%;
    border: 1px solid #eff0ed;
    &:hover { 
      background-color: #eff0ed;
    }
  }
`;
const AlartMessage = styled(Alert)`
:where(.css-dev-only-do-not-override-1g6mqjm).ant-alert{
align-items: start;
}
:where(.css-dev-only-do-not-override-1g6mqjm).ant-alert .ant-alert-icon {
margin-top: 3px;
}
`;

export const AddUserToRotaForm = ({
  form,
  startDate,
  allDay,
  rotaDutyName,
  note,
  canEditUsers,
  isMultiParish,
  filterParishIds,
  setFilterParishIds,
  handleChange,
  selectorOptionsUsers,
  required,
  userAssignedIds,
  keywordToSearchUsersByName,
  setKeywordToSearchusersByName,
  userAssignedList,
  busyUsers,
  handleRemoveAssignedUser,
  token,
  eventTitle,
}) => (
  <Container>
    <Divider />
    <Form layout="vertical" form={form}>
      <Row>
        <CdVerticalSpace>
          <RotaDutyTitle>{rotaDutyName}</RotaDutyTitle>
          {eventTitle && (
            <Space>
              <CdCalendar />
              {eventTitle}
            </Space>
          )}
          <Space style={{ paddingBottom: '8px' }}>
            <CdTime />
            {allDay
              ? gettextCatalog.getString('All day')
              : moment(startDate).format('lll')}
          </Space>

          {isMultiParish && (
            <SelectContainer
              label={gettextCatalog.getString('Filter users by parish')}
            >
              <CdParishSelect
                value={filterParishIds}
                onChange={setFilterParishIds}
                style={{ width: '100%' }}
                disabled={!canEditUsers}
              />
            </SelectContainer>
          )}
          <SelectContainer
            label={
              isMultiParish ? null : gettextCatalog.getString('Select users')
            }
          >
            <CdGroupUserSelect
              placeholder={
                <Space>
                  <CdUserAvatarLight />
                  {gettextCatalog.getString('Search user')}
                </Space>
              }
              onChange={(value) => handleChange(value)}
              value={null}
              users={selectorOptionsUsers}
              disabled={
                !canEditUsers ||
                required === userAssignedIds?.length ||
                (required && required < userAssignedIds?.length)
              }
              keyword={keywordToSearchUsersByName}
              setKeyword={setKeywordToSearchusersByName}
              style={{ width: '100%' }}
            />
          </SelectContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(required || userAssignedIds?.length > 0) && (
              <Row>
                <Space style={{ width: '100%' }}>
                  {required > userAssignedIds?.length && (
                    <Space>
                      <EventIcons.RotaMissingUsers style={{ color: 'red' }} />
                      <Typography.Text style={{ color: token.colorError }}>
                        {gettextCatalog.getString('{{ count }} missing', {
                          count: required - userAssignedIds.length,
                        })}
                      </Typography.Text>
                      /
                    </Space>
                  )}
                  <Typography.Text>
                    {gettextCatalog.getPlural(
                      userAssignedIds.length,
                      '1 user assigned',
                      '{{ count }} users assigned',
                      {
                        count: userAssignedIds.length,
                      }
                    )}
                  </Typography.Text>
                </Space>
              </Row>
            )}
            <Suspense fallback={<Spin />}>
              {userAssignedIds.length > 0 ? (
                userAssignedList.map((user) => (
                  <AssignedUser
                    key={user.id}
                    userId={user.id}
                    busy={busyUsers.includes(user.id)}
                    handleRemoveAssignedUser={handleRemoveAssignedUser}
                    canEditUsers={canEditUsers}
                    otherShifts={user.otherShifts}
                  />
                ))
              ) : (
                <Row
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '32px',
                    width: '100%',
                  }}
                >
                  <Avatar>
                    <EventIcons.Users size="lg" />
                  </Avatar>
                  <Typography.Title level={5}>
                    {gettextCatalog.getString('No users added')}
                  </Typography.Title>
                </Row>
              )}
            </Suspense>
          </div>
          <Form.Item
            label={gettextCatalog.getString('Note')}
            name="note"
            initialValue={note}
            style={{ marginBottom: '0' }}
          >
            <Note rows={2} style={{ width: '100%' }} disabled={!canEditUsers} />
          </Form.Item>
          <div style={{ width: '100%' }}>
            <AlartMessage
              type="info"
              showIcon
              message={gettextCatalog.getString(
                'Everyone you add to the rota will be notified once you click “Save & notify”. They will also receive a reminder per email 3 days before the event.'
              )}
            />
          </div>
        </CdVerticalSpace>
      </Row>
    </Form>
  </Container>
);

const AssignedUser = (props: {
  userId: number;
  handleRemoveAssignedUser;
  busy: boolean;
  canEditUsers: boolean;
  otherShifts: Shift[];
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const user = useRecoilValue(GetOrganizationUser({ id: props.userId }));

  const isUserBlocked = user && user.status === 'blocked';
  const isUserDeleted = !user;

  return (
    <RowContainer key={props.userId}>
      <Col span={20}>
        <CdVerticalSpace
          data-testid="user-details"
          style={{ marginLeft: '8px' }}
          size={4}
        >
          <CdUserAvatar
            light
            name={user.name}
            picture={user.picture}
            blocked={isUserBlocked || isUserDeleted}
          />
          {props.otherShifts.length > 0 && (
            <Space size={4}>
              <CdExclamationTriangleIcon type="warning" />
              <Typography.Text type="warning">
                {gettextCatalog.getString('Also on: {{rotas}}', {
                  rotas: props.otherShifts
                    .map((shift) => truncate(shift.name, { length: 30 }))
                    .join(', '),
                })}
              </Typography.Text>
            </Space>
          )}
        </CdVerticalSpace>
      </Col>
      <Col span={3} style={{ display: 'flex', justifyContent: 'end' }}>
        {props.busy ? (
          <Tag color={token.colorError}>
            <Space>
              <CdUsersClock />
              {gettextCatalog.getString('Busy')}
            </Space>
          </Tag>
        ) : (
          <Tag>
            <Space>
              <CdUsersClock />
              {gettextCatalog.getString('Available')}
            </Space>
          </Tag>
        )}
      </Col>

      <Col
        data-testid="remove-user-button"
        span={1}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          props.canEditUsers && props.handleRemoveAssignedUser(props.userId)
        }
      >
        <CdClose style={{ color: 'gray' }} />
      </Col>
    </RowContainer>
  );
};
