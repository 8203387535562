import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import {
  PaginatedResult,
  RotaScheme,
  RotaSchemeUpdateRequestBody,
} from '@/react/calendar/rota-scheme/types/rotaScheme.types';

export const getRotaSchemes = async (
  descending = false,
  orderBy = 'name'
): Promise<PaginatedResult<RotaScheme> | undefined> => {
  const response = await mainApi.get<PaginatedResult<RotaScheme>>(
    `/shifts/rotas/link`,
    {
      desc: descending,
      orderBy,
    }
  );
  if (!response.ok) {
    handleError(response);
    return undefined;
  }

  return response.data;
};

export const deleteRotaScheme = async (id: string): Promise<void> => {
  const response = await mainApi.delete(`/shifts/rotas/link/${id}`);
  if (!response.ok) {
    handleError(response);
    return undefined;
  }
};

export const updateRotaScheme = async (
  id: string,
  updateBody: RotaSchemeUpdateRequestBody
): Promise<void> => {
  const response = await mainApi.put(`/shifts/rotas/link/${id}`, updateBody);
  if (!response.ok) {
    handleError(response);
    return undefined;
  }
};
