import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import Calendar from '@/react/calendar';
import { CalendarPoster } from '@/react/calendar/containers/event/CalendarPoster.page';
import { EventMoreDropdown } from '@/react/calendar/containers/event/EventMoreDropdown';
import { CalendarPosterList } from '@/react/calendar/containers/event/CalendarPosterList';
import { CdPoster } from '@/react/shared/components/Icons';
import { EventDrawerHOC } from '@/react/calendar/event-details/EventDrawer';
import { CdRRuleSummary } from '@/react/calendar/event-details/components/CdRRuleSummary';

angular
  .module('cdApp.calendar')
  .component(
    'cdrCalendarPosterList',
    react2angular(sharedContext.use(CalendarPosterList), ['eventId'], [])
  );

angular
  .module('cdApp.calendar')
  .component(
    'cdrCalendar',
    react2angular(sharedContext.use(Calendar), [], ['$stateParams'])
  );

angular
  .module('cdApp.calendar')
  .component(
    'cdrPosterIcon',
    react2angular(sharedContext.use(CdPoster), [], [])
  );

angular
  .module('cdApp.calendar')
  .component(
    'cdrCalendarPoster',
    react2angular(sharedContext.use(CalendarPoster), [], ['$stateParams'])
  );

angular
  .module('cdApp.calendar')
  .component(
    'cdrEventDetailsPage',
    react2angular(
      sharedContext.use(EventDrawerHOC),
      ['eventId'],
      ['$stateParams']
    )
  );

angular
  .module('cdApp.calendar')
  .component(
    'cdrRruleSummary',
    react2angular(
      sharedContext.use(CdRRuleSummary),
      ['rrule', 'startDate'],
      ['$stateParams']
    )
  );

angular
  .module('cdApp.calendar')
  .component(
    'cdrEventMore',
    react2angular(
      sharedContext.use(EventMoreDropdown),
      ['event', 'exportEvent', 'deleteEvent', 'canAccessCalendar'],
      []
    )
  );
