import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { RotaSchemePage } from '@/react/calendar/rota-scheme/RotaSchemePage';

angular
  .module('cdApp.calendar')
  .component(
    'cdRotasLinksState',
    react2angular(sharedContext.use(RotaSchemePage), [], [])
  );
