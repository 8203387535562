import {
  faExternalLinkAlt,
  faPencilAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { gettextCatalog } from '../../services/I18nService';
import { isResourceSelected, isUserSelected } from '../hooks';
import { FilterType, SelectedCalendar } from '../store/filters';
import { RemoveCalendar, ToggleCalendar } from '../store/filters/Actions';
import { getCalendar, getParentResourceById } from '../store/filters/Selectors';
import { FeedType } from '../store/main-view/Actions';

import { showCalendarSubscribeModal } from './CalendarSubscribeModal';

import { CdTooltip } from '@/react/shared/components/cd-tooltip/CdTooltip';

/**
 * TODO: This style is repeated in `src/React/Calendar/components/CalendarItem.tsx`!
 * Style definitions should NOT be mixed with structural components!!!
 */
export const FilterListItem = styled.div<{ isDisabled?: boolean }>`
  list-style-type: none;
  line-height: 25px;
  ${({ isDisabled }) =>
    isDisabled
      ? `
    .bullet-wrapper {
      opacity: 0.4;
    }`
      : ''}

  .bullet-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: rgb(228, 228, 228);

      .bullet:not(.bullet-full)::after {
        display: none;
      }

      .bullet-label {
        width: 75%;
      }

      .filter-actions {
        display: flex;
        right: 0;

        i.fa {
          margin: 0 3px;
        }

        a:hover {
          i {
            color: #333;
          }
        }
      }
    }

    .bullet-label {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: 0.5em 1em 0.5em 1em;

      .bullet {
        margin-right: 15px;
        border-radius: 4px;
        width: 16px;
        height: 16px;
        align-self: center;
        min-width: 16px;
        min-height: 16px;
        display: block;
        cursor: pointer;

        &.color-999 {
          background-color: #517488;
          border-color: #517488;
          color: white;
        }

        &.color-998 {
          background-color: #3d5766;
          border-color: #3d5766;
          color: white;
        }

        &.color-888 {
          background-color: #333;
          border-color: #333;
          color: white;
        }

        &:not(.bullet-empty) {
          border: none;

          &::after {
            font: normal normal normal 10px/1 'Font Awesome 5 Pro';
            content: '\f00c';
            color: #fff;
            left: 3px;
            top: -6px;
            position: relative;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
          }
        }

        &.bullet-empty {
          background-color: transparent;
          border-style: solid;
          border-width: 2px;
        }
      }
      .label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% - 21px);
        padding: 0;
        color: #333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;

        &.icon-label {
          width: calc(100% - 35px);
        }

        .label.title {
          display: flex;
          flex-direction: row;
        }

        .name {
          display: block;
          width: 100%;
          font-size: 13px;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        small {
          width: 100%;
          line-height: 1.4;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .filter-actions {
      display: none;
      padding-right: 5px;

      svg {
        opacity: 0.6;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }
`;

interface DisplayFilterItem {
  id: number | string;
  name: string;
  color?: number;
  image?: string;
  filterType?: FilterType;
  parentResourceId?: number;
  onEdit: () => void;
}

export interface UserItemProps {
  item: SelectedCalendar;
  feedType?: FeedType;
  icon?: IconDefinition;
  onEdit?: () => void;
}

export const getClassName = (
  isSelected: boolean,
  item: DisplayFilterItem
): string => {
  const color: number = item.filterType === FilterType.users ? 888 : item.color;
  return `bullet color-${color || 0} ${
    isSelected ? 'bullet-full' : 'bullet-empty'
  }`;
};

const hasParentResource = (item: DisplayFilterItem): boolean =>
  item.filterType === FilterType.resources &&
  _.isFinite(item && item.parentResourceId);

const FilterItem: FunctionComponent<UserItemProps> = ({
  item,
  feedType,
  icon,
  onEdit,
}) => {
  const dispatch = useDispatch();
  const onClose = useCallback(
    () => dispatch(RemoveCalendar(item)),
    [item, dispatch]
  );
  const onToggle = useCallback(
    () => dispatch(ToggleCalendar(item)),
    [item, dispatch]
  );
  const calendar = useSelector(
    getCalendar(item.id, item.filterType),
    _.isEqual
  );
  const parentResource = useSelector(
    getParentResourceById(calendar && calendar.parentResourceId),
    _.isEqual
  );
  const hasGroupedResourcesPackage: boolean = _.get(
    window,
    'cdApp.showChurchSelector'
  );
  const isSelected = useSelector(
    feedType === 'user' ? isUserSelected(item.id) : isResourceSelected(item.id)
  );

  return (
    <FilterListItem
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div className="bullet-wrapper">
        <div
          className="bullet-label"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onToggle();
          }}
        >
          <span
            className={getClassName(isSelected, { ...item, ...calendar })}
          />
          <div className="label">
            <span className="name">
              {icon ? (
                <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} />
              ) : null}
              {calendar ? calendar.name || calendar.email : ''}
            </span>
            {calendar &&
            hasGroupedResourcesPackage &&
            hasParentResource({ ...item, ...calendar }) ? (
              <small className="u-text-muted">
                {_.get(parentResource, 'name')}
              </small>
            ) : null}
          </div>
        </div>

        <span className="filter-actions">
          {feedType ? (
            <CdTooltip title={gettextCatalog.getString('Export to iCal')}>
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                onClick={() =>
                  showCalendarSubscribeModal({ feedType, feedId: item.id })
                }
                style={{ marginRight: 5, cursor: 'pointer' }}
              />
            </CdTooltip>
          ) : null}
          {onEdit ? (
            <CdTooltip title={gettextCatalog.getString('Edit')}>
              <FontAwesomeIcon
                icon={faPencilAlt}
                onClick={onEdit}
                style={{ marginRight: 5, cursor: 'pointer' }}
              />
            </CdTooltip>
          ) : null}
          {onClose ? (
            <CdTooltip title={gettextCatalog.getString('Remove')}>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={onClose}
                style={{ marginRight: 5, cursor: 'pointer' }}
              />
            </CdTooltip>
          ) : null}
        </span>
      </div>
    </FilterListItem>
  );
};

// FilterItem.whyDidYouRender = {
//   customName: 'Filter_Item',
// };

export default React.memo(FilterItem, _.isEqual);
