import React from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { Button, Space, Typography } from 'antd';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdDownloadIcon } from '@/react/shared/components/Icons';
import { customTheme } from '@/react/shared/components/cd-spreadsheet-importer/helpers/helpers';

export default function CdSpreadsheetImport({
  exampleDownloadLink,
  fields,
  onClose,
  onSubmit,
  ...rest
}: {
  exampleDownloadLink: string;
  fields?: any[];
  onClose?: () => void;
  onSubmit?: (data: any) => Promise<void>;
  [key: string]: unknown;
}) {
  return (
    <ReactSpreadsheetImport
      {...rest}
      isOpen={true}
      onClose={onClose}
      onSubmit={onSubmit}
      fields={fields}
      translations={{
        uploadStep: {
          title: gettextCatalog.getString('Upload file'),
          manifestTitle: gettextCatalog.getString('Data that we expect'),
          manifestDescription: (
            <Space direction="vertical">
              <Typography.Text key="descriptionText">
                {gettextCatalog.getString(
                  '(You will have a chance to rename or remove columns in next steps)'
                )}
              </Typography.Text>
              <Button
                href={exampleDownloadLink}
                type="primary"
                key="downloadButton"
                style={{ color: 'white' }}
                icon={<CdDownloadIcon />}
              >
                {gettextCatalog.getString('Download example file')}
              </Button>
            </Space>
          ),
          maxRecordsExceeded: (maxRecords: string) =>
            gettextCatalog.getString(
              'Too many records. Up to {{ maxRecords }} allowed',
              { maxRecords },
              'Max number of rows in table allowed for safeguarding user upload'
            ),
          dropzone: {
            title: gettextCatalog.getString('Upload .xlsx, .xls or .csv file'),
            buttonTitle: gettextCatalog.getString('Select file'),
            activeDropzoneTitle: gettextCatalog.getString('Drop file here...'),
          },
        },
        selectHeaderStep: {
          title: gettextCatalog.getString('Select the header row'),
          nextButtonTitle: gettextCatalog.getString('Next'),
          backButtonTitle: gettextCatalog.getString('Back'),
        },
        matchColumnsStep: {
          title: gettextCatalog.getString('Match Columns'),
          nextButtonTitle: gettextCatalog.getString('Next'),
          backButtonTitle: gettextCatalog.getString('Back'),
          userTableTitle: gettextCatalog.getString('User Table'),
          templateTitle: gettextCatalog.getString('Will become'),
          selectPlaceholder: gettextCatalog.getString('Select column...'),
          ignoredColumnText: gettextCatalog.getString('Column ignored'),
          subSelectPlaceholder: gettextCatalog.getString('Select...'),
          matchDropdownTitle: gettextCatalog.getString('Match'),
          unmatched: gettextCatalog.getString('Unmatched'),
          duplicateColumnWarningTitle: gettextCatalog.getString(
            'Another column unselected'
          ),
          duplicateColumnWarningDescription: gettextCatalog.getString(
            'Columns cannot duplicate'
          ),
        },
        validationStep: {
          title: gettextCatalog.getString('Validate data'),
          nextButtonTitle: gettextCatalog.getString('Confirm'),
          backButtonTitle: gettextCatalog.getString('Back'),
          noRowsMessage: gettextCatalog.getString('No data found'),
          noRowsMessageWhenFiltered: gettextCatalog.getString(
            'No data containing errors'
          ),
          discardButtonTitle: gettextCatalog.getString('Discard selected rows'),
          filterSwitchTitle: gettextCatalog.getString(
            'Show only rows with errors'
          ),
        },
        alerts: {
          confirmClose: {
            headerTitle: gettextCatalog.getString('Exit import flow'),
            bodyText: gettextCatalog.getString(
              'Are you sure? Your current information will not be saved.'
            ),
            cancelButtonTitle: gettextCatalog.getString('Cancel'),
            exitButtonTitle: gettextCatalog.getString('Exit flow'),
          },
          submitIncomplete: {
            headerTitle: gettextCatalog.getString('Errors detected'),
            bodyText: gettextCatalog.getString(
              'There are still some rows that contain errors. Do you want to submit anyway?'
            ),
            bodyTextSubmitForbidden: gettextCatalog.getString(
              'There are still some rows containing errors.'
            ),
            cancelButtonTitle: gettextCatalog.getString('Cancel'),
            finishButtonTitle: gettextCatalog.getString('Submit'),
          },
          submitError: {
            title: gettextCatalog.getString('Error'),
            defaultMessage: gettextCatalog.getString(
              'An error occurred while submitting data'
            ),
          },
          unmatchedRequiredFields: {
            headerTitle: gettextCatalog.getString('Not all columns matched'),
            bodyText: gettextCatalog.getString(
              'There are required columns that are not matched or ignored. Do you want to continue?'
            ),
            listTitle: gettextCatalog.getString('Columns not matched:'),
            cancelButtonTitle: gettextCatalog.getString('Cancel'),
            continueButtonTitle: gettextCatalog.getString('Continue'),
          },
          toast: {
            error: gettextCatalog.getString('Error'),
          },
        },
      }}
      customTheme={customTheme}
      autoMapSelectValues
      isNavigationEnabled
    />
  );
}
