import { Button, Col, Dropdown, Row, Space } from 'antd';
import React, { useContext } from 'react';

import { launchExportIcalModal } from '../modals/ExportIcal.modal';
import { UponCloseInterface } from '../../hooks/use-close-modal';
import { openEventDrawer } from '../../EventDrawer';
import { showPrintModal } from '../PrintModal';

import {
  CdAngleDown,
  CdCopyIcon,
  CdDeleteIcon,
  CdOpenInNewWindow,
  EventIcons,
  CdLink,
  CdPrint,
} from '@/react/shared/components/Icons';
import {
  EventAccessContext,
  EventLoadingState,
  SaveAndCloseInterface,
} from '@/react/calendar/event-details/hooks/use-event';
import { gettextCatalog } from '@/react/services/I18nService';
import { showCopyModal } from '@/react/shared/components/cd-copy-modal/CdrCopyModal';
import { getExternalLink } from '@/react/homepage/services/external-link.service';

export const EventControls = (props: {
  onSave: ({ notify }: { notify: boolean }) => Promise<void>;
  onDelete: () => void;
  saveProcessing: EventLoadingState;
  saveAndClose: (props: SaveAndCloseInterface) => Promise<void>;
  eventId: number;
  close: (input?: UponCloseInterface) => Promise<void>;
  areAnyUsersBooked: boolean;
  isPublicEvent: boolean;
  eventUrl: string;
}) => {
  const size = 'large';

  const { eventAccess } = useContext(EventAccessContext);
  const { canEdit, canNotify, canDelete, canCreate } = eventAccess;
  const dropDownMenueHandler = (key: string) => {
    switch (key) {
      case 'exportIcal':
        return launchExportIcalModal({ eventId: props.eventId });

      case 'delete':
        return props.onDelete();

      case 'copyLink':
        return showCopyModal({
          title: gettextCatalog.getString('Copy link'),
          subTitle: gettextCatalog.getString(
            'You can copy the link below and share it on your social media.'
          ),
          copyValue: props.eventUrl
            ? props.eventUrl
            : getExternalLink(props.eventId, 'event'),
        });

      case 'makeACopy':
        return props
          .saveAndClose({
            title: gettextCatalog.getString('Unsaved changes'),
            message: gettextCatalog.getString(
              'To create a copy of this event please save it first.'
            ),
            okText: gettextCatalog.getString('Save & make a copy'),
            cancelText: gettextCatalog.getString('Cancel'),
            skipRedirecting: false,
          })
          .then((eventId: any) => {
            openEventDrawer({ copyEventId: eventId || props.eventId });
          });
      case 'print':
        return showPrintModal({
          eventId: props.eventId,
          accessToSensitiveInfo: eventAccess?.fields.secureInformation.canEdit,
        });
      default:
        break;
    }
  };

  return (
    <Row>
      <Col flex="none">
        <Space style={{ width: '100%' }}>
          <Button
            type="primary"
            size={size}
            onClick={() =>
              props.saveProcessing === EventLoadingState.NOT_LOADING &&
              props.onSave({ notify: false })
            }
            loading={props.saveProcessing === EventLoadingState.SAVING}
            disabled={!(canEdit || canCreate)}
          >
            {gettextCatalog.getString('Save')}
          </Button>
          <Button
            size={size}
            onClick={() =>
              props.saveProcessing === EventLoadingState.NOT_LOADING &&
              props.onSave({ notify: true })
            }
            icon={<EventIcons.Notify />}
            loading={
              props.saveProcessing === EventLoadingState.SAVING_AND_NOTIFYING
            }
            disabled={!canNotify || !props.areAnyUsersBooked}
          >
            {gettextCatalog.getString('Save & Notify')}
          </Button>
          <Dropdown
            menu={{
              onClick: ({ key }) => {
                dropDownMenueHandler(key);
              },
              items: [
                {
                  label: props.eventId
                    ? gettextCatalog.getString('Make a copy')
                    : gettextCatalog.getString('Save & copy'),
                  key: 'makeACopy',
                  disabled: props.eventId
                    ? !(canEdit && canCreate)
                    : !canCreate,
                  icon: <CdCopyIcon fixedWidth />,
                },
                {
                  label: gettextCatalog.getString('Export iCal'),
                  key: 'exportIcal',
                  disabled: !props.eventId,
                  icon: <CdOpenInNewWindow fixedWidth />,
                },
                {
                  label: gettextCatalog.getString('Copy event URL'),
                  key: 'copyLink',
                  disabled: !props.eventId || !props.isPublicEvent,
                  icon: <CdLink fixedWidth />,
                },
                {
                  label: gettextCatalog.getString('Print'),
                  key: 'print',
                  disabled: !props.eventId,
                  icon: <CdPrint fixedWidth />,
                },
                {
                  type: 'divider',
                },
                {
                  label: gettextCatalog.getString('Delete'),
                  key: 'delete',
                  disabled: !props.eventId || !canDelete,
                  icon: <CdDeleteIcon fixedWidth />,
                },
              ],
            }}
          >
            <Button size={size}>
              <Space>
                {gettextCatalog.getString('More')}
                <CdAngleDown />
              </Space>
            </Button>
          </Dropdown>
          {props.close && (
            <Button size={size} onClick={() => props.close()}>
              {gettextCatalog.getString('Cancel')}
            </Button>
          )}
        </Space>
      </Col>
    </Row>
  );
};
