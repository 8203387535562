import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { AuthenticateSSOToken } from '../store/login';

import cdApp from '@/react/config';

export const useSso = ({ idToken, state, code }) => {
  const { accessToken, error, email } = useRecoilValue(
    AuthenticateSSOToken({ idToken, state, code })
  );

  useEffect(() => {
    if (!accessToken && !error) return;

    // eslint-disable-next-line eqeqeq
    const isMobile = localStorage.getItem('CD_MOBILE_SSO') == 'true';

    if (error && isMobile) {
      window.postMessage(JSON.stringify({ error }));
    }

    if (typeof accessToken !== 'string') return;
    if (isMobile) {
      window.postMessage(JSON.stringify({ accessToken }));
    } else {
      localStorage.setItem('churchdesk.accessToken', accessToken);
      window.location.href = `${cdApp.references.appUrl}/choose`;
    }
  }, [accessToken, error]);

  return { accessToken, error, email };
};
